.header-menu-wrapper:after {
    background-color: #ffffff !important;
}

.h-fixed nav ul li a {
    color: #000000 !important;
}

.side-menu-open {
    background-color: rgb(138 129 129) !important;
}
.min-height-130{
    min-height: 60px;
}
.copy-right ul li , .copy-right ul li a {
    color: #ffffff;
}
.footer-item ul li a{
    color: #000000;
}
.black-bg{
    background-color: #000000;
}
.k-inner .k-box {
    padding: 0 10%;
}

.k-inner img {
    width: 100%;
}

.k-inner .k-box h3 {
    color: #000000;
    text-align: left;
    margin: 0;
}

.k-inner .k-box p {
    color: #11111F;
    margin: 20px 0 0;
}

.k-inner {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 0 10px 0;
}
.footer-area{
    padding: 125px 0 20px !important;
    /* padding: 60px 0 20px !important; */
    background: #F4F7FA !important;
    border-top: 1px solid #E6E6E4 !important;
}
.footer-item .footer__title {
    font-size: 17px;
    
}
.text-black{
    color: #000000;
}
.text-align {
    text-align: center;
}
.inline-block{
    display: inline-block;
}
a{
    color: #0073BB;
}
.inline-flex{
    display: inline-flex;
}
.social-footer {
    background: #fff;
    border-top: 1px solid #E6E6E4;
    display: inline-block;
    width: 100%;
    padding: 20px 0;
    vertical-align: top;
}

.social-footer ul {
    margin: 0;
    padding: 0;
}

.social-footer ul li {
    display: inline-block;
    vertical-align: middle;
    list-style: none;
    margin: 0 10px;
}
.social-footer ul li a img {
    height: 30px;
    border-radius: 30px;
}

.k-footer-logo{
    display: inline-block;
    width: 100%;
    background: #f3f7fa;
    padding: 15px 0;
    vertical-align: top;
}
.card-item p.m-p {
    line-height: normal;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #828282;
    margin-top: -4px;
}
.mail {
    margin: 18px 0 0;
}
.media-left img {
    height: 80px;
}

.media-object {
    display: block;
}

.media-heading {
    margin-top: 0;
    margin-bottom: 5px;
}

.m-h4 {
    font-size: 20px;
    color: #2b2f32;
    margin: 0;
    line-height: normal;
}
.media-body.m-body h4.media-heading.m-h4 a {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    line-height: normal;
}

.m-p {
    font-size: 14px;
    color: #838486;
}

.display-inline{
    display: inline;
}


.bg-black1{
    background-color: #000000;
    color: #ffffff;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.k-class-2{
    display: inline-block;
    width: 100%;
    /* padding: 15px 0; */
    vertical-align: middle;
}

.k-class{
    display: inline-block;
    width: 100%;
    padding: 15px 0;
    vertical-align: top;
}

.mb_5{
    margin-bottom: 5px;
}

.mt_5{
    margin-top: 5px;
}
.discover1{
    position: relative;
    top: 25%;
}
.discover {
    margin-top: 40px;
    margin-bottom: 40px;
}

.discover h1 {
    font-size: 24px;
    color: #000000;
    font-weight: 700;
}

.discover p {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    margin: 20px 0 0;
}

.bg-black {
    background-color: #000000;
    color: #ffffff;
    margin-top: 40px;
    margin-bottom: 40px;
}

.bg-black h1 {
    font-size: 28px;
    /* padding-top: 7px; */
    padding-bottom: 7px;
}

.bg-row{
    padding-top: 40px;
    padding-bottom: 40px;
}



@media (max-width: 767px){
    .bg-row {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
    .bg-black h1 {
        font-size: 20px;
        line-height: 28px;
    }

    .pb{
        padding-bottom: 8px;
    }

    /* .btn-box{
        margin-bottom: 60px;
    } */

    .discover1{
        top: 0;
        padding-top: 15px;
    }

    .social-row {
        margin-right: -15px;
        margin-left: -15px;
        margin-top: 5px;
        padding-right: 8px;
        text-align: -webkit-right;
        font-size: 3.5vw;
    }
}

@media (max-width: 1199px){
    .side-menu-open {
        display: block;
        right: 0 !important; 
        left: auto !important;
    }
}

.connection {
    padding-top: 40px;
}

.connection h1 {
    font-size: 45px;
    color: #000000;
    font-weight: 700;
}

.connection p {
    margin-top: 20px;
    color: #000000;
    font-size: 18px;
}

.map-image img {
    display: block;
    width: 100%;
}
.ml-20 {
    margin-left: 20px;
}

.mr-20 {
    margin-right: 20px;
}

.ml-15 {
    margin-left: 15px;
}

.mr-15 {
    margin-right: 15px;
}

.ml-10 {
    margin-left: 10px;
}

.mr-10 {
    margin-right: 10px;
}

.ml-5 {
    margin-left: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.ml-0 {
    margin-left: 0px;
}

.mr-0 {
    margin-right: 0px;
}

.pl-20 {
    padding-left: 20px;
}

.pr-20 {
    padding-right: 20px;
}

.pl-15 {
    padding-left: 15px;
}

.pr-15 {
    padding-right: 15px;
}

.pl-10 {
    padding-left: 10px;
}

.pr-10 {
    padding-right: 10px;
}

.pl-5 {
    padding-left: 5px;
}

.pr-5 {
    padding-right: 5px;
}

.pl-0 {
    padding-left: 0px;
}

.pr-0 {
    padding-right: 0px;
}

.header-menu-wrapper {
    background-color: transparent !important;
    background: transparent !important;
}

.landing-top-main {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background: #11111F;
}

.landing-top-main .landing-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33.33%;
}

.landing-box:hover .overlay {
    background: rgba(255, 0, 0, 0.5);
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    top: 0;
    bottom: 0;
}

.landing-box .overlay {
    background: none;
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    top: 0;
    bottom: 0;
    -webkit-transition: background-color 1s ease-out;
    -moz-transition: background-color 1s ease-out;
    -o-transition: background-color 1s ease-out;
    transition: background-color 1s ease-out;
}

.landing-box h1 {
    text-align: center;
    /* width: 100% !important; */
    font-size: 35px;
    /* margin: 0 !important; */
    /* padding: 0 !important; */
    color: #ffffff;
    /* padding-top: 5px; */
}

.landing-box img {
    width: 100%;
}

.landing-box:hover .overlay a {
    opacity: 1;
}

.landing-box .overlay a {
    opacity: 0;
    display: inline-block;
    margin-top: 10px;
    padding: 5px 15px 8px;
    border: 1px solid #fff;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    line-height: 16px;
    border-radius: 30px;
    width: 160px;
    text-align: center;
}