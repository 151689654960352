@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
    font-family: 'Poppins', sans-serif;
}

main.Mobile.home-1 section.hero-wrapper.hero-wrapper2 {
    padding-top: 0;
    padding-bottom: 0;
    height: auto;
    background: #F11200;
    margin-top: 20px;
}

main.Mobile .col-lg-4.column-td-6.col-4.padding-0 {
    padding: 0;
}

main.Mobile .browse-category {
    margin-bottom: 0;
    border-radius: 0;
}

main.Mobile .browse-category .cat__name {
    font-size: 12px;
}

main.Mobile .category.text-center {
    margin: 0;
    padding: 0;
}

main.Mobile .header-area {
    background: #F11200;
    padding: 10px 0;
}

main.Mobile .logo {
    margin: 0 auto;
}

main.Mobile .logo img {
    width: auto;
}

main.Mobile .side-menu-open {
    background-color: transparent !important;
}

.slick-slide img {
    display: inline-block;
    min-height: 105px;
}

.HomeHeading h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000;
}

main.Mobile .padding-top-35px {
    padding-top: 0;
}

.HomeHeading {
    padding: 15px 0 14px 10px;
}

main.Mobile .gallery-item {
    background: transparent;
}

main.Mobile .slick-slider .slick-arrow {
    background: #fff;
    right: 0;
    border: 0;
    color: #000;
}

main.Mobile button.slick-arrow.slick-prev {
    left: 0;
}

main.Mobile .slick-slider .slick-arrow:before {
    border-color: #000;
}

.Slider {
    background: #fff;
    margin: 25px 0 15px;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
}

.OpenAppBtn {
    display: inline-block;
    margin: 0 auto 20px;
    text-align: center;
    width: 100%;
}

.OpenAppBtn a.btn.btn-primary {
    font-size: 16px;
    width: 310px;
    height: 60px;
    line-height: 60px;
    padding: 0;
    background: #0073BB;
    border-color: #0073BB;
}

main.Mobile .card-item.blog-card a.card-image-wrap .card-image {
    height: 60px;
    padding-top: 10%;
    width: 60px;
    display: inline-block;
    border-radius: 3px;
}

main.Mobile .card-item.blog-card {
    margin-top: 0px;
    border-radius: 0;
    display: grid;
    grid-template-columns: 60px auto;
    border: 0;
    /* border-bottom: 1px solid #eeeeef; */
    padding: 15px 0 0;
    border-top: 1px solid #eeeeef;
    margin: 0 0 -1px 0;
}

main.home-1 .side-menu-open {
    transform: translateY(-45%);
    padding: 0;
    text-align: right;
}

main.Mobile .blog-card .card-title {
    margin-top: 0;
}

.ListHeading h1, .Results h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    padding-left: 15px;
}

.Results h1 {
    font-size: 16px;
}

main.Mobile .cities .list {
    background: #fff;
}

main.Mobile .react-tabs__tab-list {
    margin: 0;
}

main.Mobile .rating-rating.rating-detail.landing {
    margin-top: 0;
    padding: 0;
    margin: 0;
    height: 20px;
}

main.Mobile .k-class.white-bg .copy-right ul li:last-child {
    border-right: 0;
    padding: 0;
    font-size: 14px;
}

ul.FooterNavBar {
    text-align: center;
    background: #F5F5F5;
    padding-top: 15px;
}

ul.FooterNavBar li {
    display: inline-block;
    text-align: center;
}

ul.FooterNavBar li a {
    margin: 0 5px;
    color: #000;
    font-size: 14px;
}

main.Mobile .k-class.white-bg {
    background: #F5F5F5;
    border-top: 0;
    margin-top: 0;
}

main.Mobile .OpenAppBtn.ViewBus a.btn.btn-primary {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 3px;
    color: #000000;
    margin-top: 15px;
}

main.Mobile .main-search-input .main-search-input-item input.react-autosuggest__input {
    width: 100%;
    line-height: inherit;
    padding: 12px 20px 12px 35px;
    font-size: 14px;
    color: #333;
    border: 0;
    resize: none;
    font-weight: 500;
    transition: all 0.3s;
    height: 28px;
}

main.Mobile .contact-form-action .form-group .form-icon {
    top: 0;
    left: 10px;
    color: #000;
    font-size: 20px;
}

a.app-btn {
    background: #CA2D2D;
    border: 1px solid #A20000;
    box-sizing: border-box;
    border-radius: 3px;
    color: #fff;
    width: 137px;
    height: 30px;
    line-height: 30px;
    opacity: 0;
}

a.app-btn img {
    margin: -4px 0 0 0;
}

main.Mobile .menu-full-width .logo {
    position: absolute;
    left: 15px;
    right: 0;
    top: 3px;
    bottom: 0;
}

.btns {
    position: relative;
    border-bottom: 1px solid #ccc;
    padding: 10px 10px 10px 8px;
    background-color: #f5f5f5;
}

.btns button {
    background: transparent;
    border: 0;
}

.btns button.Map {
    margin: 0 15px 0 15px;
}

/* .btns {
    display: inline-block;
    width: 100%;
    padding: 0 15px;
    margin-top: 30px;
} */

.Results {
    margin-top: 20px;
}

.BreadCrumb {
    padding-left: 15px;
}

.BreadCrumb p {
    font-size: 12px;
    color: #000;
}

main.Mobile.home-1.listing .blog-card .card-title {
    color: #000;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}

.right-btns.pull-right {
    position: static;
}

main.Mobile p.card-sub.card-custom.mt-3 {
    color: #0073BB;
    font-size: 12px;
}

main.Mobile p.card-sub.card-custom.address.mt-3 {
    font-size: 12px;
    color: #000;
}

.PhoneNo {
    font-size: 12px;
    color: #828282;
}

.Descp p {
    font-size: 12px;
    line-height: 18px;
    color: #A5A5A5;
}

main.Mobile .side-nav-container.catagoery .side-menu-ul {
    border-bottom: 1px solid #C4C4C4;
}

main.Mobile .side-nav-container.catagoery .side-menu-ul li {
    display: inline-block;
    border-right: 1px solid #C4C4C4;
}

main.Mobile .side-nav-container.catagoery.active .side-menu-wrap {
    padding-top: 0;
    margin-top: 50px;
}

main.Mobile .side-nav-container.catagoery .side-menu-ul li:last-child {
    border-right: 0;
}

main.Mobile .side-nav-container.catagoery .side-menu-ul li a, .side-user-panel .side-menu-ul li a {
    font-size: 14px;
    color: #000;
    display: block;
    text-transform: capitalize;
    font-weight: 500;
    padding: 14px 10px;
    transition: all 0.3s;
    position: relative;
}

main.Mobile .side-nav-container.catagoery .side-menu-ul li span, .side-user-panel .side-menu-ul li span {
    color: #000;
}

main.Mobile .side-nav-container.catagoery, .side-user-panel {
    width: 100%;
    height: auto;
    background-color: #EEEEEF;
    position: absolute;
}

main.Mobile .side-nav-container.catagoery.active .side-menu-wrap .dropdown button {
    background: transparent;
    border: 0;
    color: #000;
    padding: 0;
}

.DropDownBtn {
    margin-top: 50px;
    background: #EEEEEF;
    border-bottom: 1px solid #C4C4C4;
}

.DropDownBtn .dropdown {
    display: inline-block;
}

.DropDownBtn .dropdown button {
    background: transparent;
    border: 0;
    color: #000;
    font-size: 14px;
    border-right: 1px solid #C4C4C4;
    border-radius: 0;
}

.DropDownBtn .dropdown .dropdown-menu.show {
    width: 100%;
    min-width: 360px;
    border: 0;
    border-radius: 0;
    margin-top: 0px;
}

.DropDownBtn .dropdown button:nth-child(3) {
    border-right: 0;
}

.DropDownBtn .dropdown .dropdown-item {
    display: inline-block;
    width: 50%;
    font-size: 13px;
}

main.Mobile section.breadcrumb-area.listing-detail-breadcrumb.detail-gallery {
    width: 100%;
    max-width: initial;
    margin: 0 auto;
    position: relative;
    background-image: none;
    height: 150px;
}

main.Mobile .listing-detail-breadcrumb .breadcrumb-content .breadcrumb__title {
    color: #000;
    font-weight: normal;
    font-size: 24px;
    line-height: normal;
    display: inline-block;
    margin: 0;
}

.BookMarkIcon {
    display: inline-block;
    position: static;
    color: #000;
    font-size: 27px;
    margin-top: 5px;
}

.BookMarkIcon a {
    color: #000;
}

main.Mobile section.breadcrumb-area.listing-detail-breadcrumb .breadcrumb-wrap {
    position: absolute;
    z-index: 9;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
}

main.Mobile .review-rating-summary.ratings p.stats-average__rating-rating {
    color: #000;
    font-size: 14px;
}

main.Mobile .rating-rating.rating-detail.landing span span span img.icon {
    width: 17px;
}

.ProPrice {
    color: #000;
    display: inline-block;
}

span.PriceDot {
    width: 6px;
    height: 6px;
    background: #000;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    left: 5px;
    top: -2px;
}

/* ///////////////////////////////////////////////////////////////////////*/

main.Mobile .m-bottom_buttons {
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 3px;
    text-align: center;
    height: 37px;
    margin: 0 0 8px 0;
}

main.Mobile .m-bottom_buttons button, .m-bottom_buttons a {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #0073BB;
}

main.Mobile .m-bottom_buttons a.btn-link {
    display: block;
    padding-top: 8px;
}

main.Mobile .pagination-btn {
    text-align: center;
    margin: 20px 0 20px 0;
}

main.Mobile .pagination-btn button {
    color: #fff;
    background: #0097ec;
    border: 1px solid #0097ec;
    border-radius: 4px;
    width: 145px;
    height: 40px;
}

main.Mobile .pagination-btn button.prev-btn {
    margin-right: 10px;
}

main.Mobile .pagination-btn button.next-btn {
    margin-left: 10px;
}

main.Mobile .list-filter .SelectPrice, main.Mobile .list-filter .SelectSort {
    background: #F5F5F5;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 7px;
    width: 100%;
    margin-bottom: 15px;
}

main.Mobile .list-filter {
    margin: 0 15px;
}

main.Mobile .list-filter .SelectPrice .custom-checkbox {
    width: 25%;
    height: 35px;
    line-height: 35px;
}

.SelectSort .custom-checkbox {
    display: inline-block;
    text-align: center;
    width: 50%;
    height: 35px;
    line-height: 35px;
}

main.Mobile .SelectPrice .custom-checkbox input[type=checkbox]:checked+label {
    background-color: #0097EC;
    border-color: #0097EC;
    color: #fff;
    font-size: 16px;
}

main.Mobile .SelectPrice .custom-checkbox input[type=checkbox]:not(:checked)+label, .custom-checkbox input[type=checkbox]:checked+label {
    font-size: 16px;
}

main.Mobile .SelectSort .custom-checkbox input[type=radio]:checked+label {
    background-color: #0097EC;
    border-color: #0097EC;
    border-radius: 7px;
    color: #fff;
}

main.Mobile .SelectSort .custom-checkbox:first-child {
    border-right: 1px solid;
}

main.Mobile .SelectSort .custom-checkbox input[type=radio]:not(:checked), .custom-checkbox input[type=radio]:checked {
    position: absolute;
    left: -99999px;
}

main.Mobile .SelectSort .custom-checkbox input[type=radio]:checked+label[for=chb3-Recommended] {
    border-radius: 7px 0 0 7px;
}

main.Mobile .SelectSort .custom-checkbox input[type=radio]:checked+label[for=chb3-Highest-Rated] {
    border-radius: 0 7px 7px 0;
}

main.Mobile .list-filter p {
    font-size: 16px;
    color: #000000;
    margin-bottom: 15px;
    margin-top: 15px;
}

main.Mobile .SelectPrice.SelectSuggestion {
    text-align: center;
}

main.Mobile .SelectPrice.SelectSuggestion .custom-checkbox input[type=checkbox]:not(:checked)+label, .custom-checkbox input[type=checkbox]:checked+label {
    font-size: 14px;
}

main.Mobile .list-filter .SelectPrice.SelectSuggestion .custom-checkbox {
    width: 100%;
    height: 35px;
    line-height: 35px;
}

main.Mobile .btns-search {
    position: relative;
    border-bottom: 1px solid #ccc;
    padding: 10px 10px 10px 8px;
    background-color: #f5f5f5;
}

main.Mobile .btns-search button {
    color: #666;
    background: #fff;
    border: 1px solid #ccc;
    width: 75px;
    height: 30px;
    border-radius: 3px;
    font-size: 14px;
}

main.Mobile .btns button {
    color: #666;
    background: #fff;
    border: 1px solid #ccc;
    width: 70px;
    height: 30px;
    font-size: 14px;
    border-radius: 3px;
}

main.Mobile .btns .right-btns.pull-right button.List {
    width: 40px;
    border-radius: 3px 0 0 3px;
}

main.Mobile .btns .right-btns.pull-right button.Map {
    width: 46px;
    border-radius: 0 3px 3px 0;
    margin-left: -1px;
    margin-right: 0px;
}

main.Mobile .btns button.active {
    background: none;
    background-color: #e6e6e6;
}

main.Mobile .mobile-gallery {
    margin-top: 50px;
}

.gallery-Count {
    position: relative;
    background-color: #333;
    padding: 16px 35px;
    text-align: center;
    border-bottom: 1px solid #1d1d1d;
    color: #fff;
}

/* main.Mobile .mobile-gallery button.BrainhubCarousel__arrows.BrainhubCarousel__arrowLeft {
    position: absolute;
    top: 7px;
}

main.Mobile .mobile-gallery button.BrainhubCarousel__arrows.BrainhubCarousel__arrowRight {
    position: absolute;
    top: 7px;
    right: 0px;
}

main.Mobile .mobile-gallery .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
    width: 100% !important;
}

main.Mobile .mobile-gallery .BrainhubCarouselItem {
    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
} */

main.Mobile .m-bottom_buttons.details {
    background: #0073BB;
    height: 46px;
}

/* ////////////////////// */

main.Mobile .m-bottom_buttons.details button, .m-bottom_buttons.details a {
    line-height: 30px;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 400;
}

main.Mobile section.single-listing-area .service {
    background: #F5F5F5;
    padding: 15px 10px 15px 10px;
    border-radius: 3px 3px 0 0;
}

main.Mobile section.single-listing-area .service p {
    font-size: 14px;
    color: #828282;
    padding-bottom: 20px;
    padding-top: 10px;
}

main.Mobile .img-list {
    width: 100%;
    position: relative;
}

main.Mobile .img-list img.card__img0 {
    width: 100%;
}

/* ///////////////////////////////////////////////////////////////////////*/

.PriceBox span.DishName {
    display: inline-block;
    width: auto;
    height: auto;
    background: transparent;
    color: #000;
    position: static;
    margin: 0px 0 0 10px;
}

.OpenTime {
    color: #000;
}

.OpenTime span {
    display: inline-block;
}

.OpenTime p {
    display: inline-block;
    font-size: 13px;
    margin: 0 0 0 10px;
}

.Hours {
    color: #0AB3C9;
    padding-left: 25px;
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 10px;
}

.d-btns ul {
    width: 100%;
    display: inline-block;
}

.d-btns ul li {
    display: inline-block;
    width: 25%;
    text-align: center;
}

.d-btns ul li a.btn span {
    display: inline-block;
    width: 100%;
}

.d-btns ul li a.btn {
    padding: .375rem 0;
}

.covid.two {
    border: 1px solid #EEEEEF;
    padding: 10px;
    margin-bottom: 15px;
}

main.Mobile .single-listing-wrap.details .listing-description {
    border-bottom: 0;
    margin-bottom: 0;
}

main.Mobile .single-listing-wrap.details .comments-wrap {
    border-top: 0;
    margin-top: 0;
    padding-top: 0px;
}

main.Mobile section.single-listing-area ul.comments-list li {
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 0;
    padding-top: 0;
}

main.Mobile .comment.listing-details .user-img {
    width: 32px;
    height: 32px;
    border-radius: 3px;
}

main.Mobile .comment .avatar__img {
    border-radius: 3px;
}

main.Mobile .comment.listing-details {
    grid-template-columns: 32px auto;
}

main.Mobile.home-1.listing-details .ReviewsBtn {
    display: inline-flex;
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 0;
    width: 100%;
}

main.Mobile.home-1.listing-details .ReviewsBtn .use {
    width: 100%;
    text-align: center;
}

main.Mobile.home-1.listing-details .ReviewsBtn button.btn.btn-none {
    border: 0;
    border-radius: 0;
    margin: 0;
    min-width: auto;
}

main.Mobile.home-1.listing-details .img-list {
    position: relative;
    width: 100%;
    display: flex;
    padding-top: 1.5px;
    overflow: hidden;
}

main.Mobile.home-1.listing-details .GalleryPhotos {
    margin-left: -1.5px;
    margin-right: -1.5px;
    width: calc(100% + 3px);
    display: flex;
}

.card-item.SingleItem {
    position: relative;
    cursor: pointer;
    padding: 1.5px;
    text-decoration: none!important;
    flex: 1 1;
}

.card-item.SingleItem .card-image {
    height: 200px;
    position: relative;
    padding-top: 50%;
    overflow: hidden;
    border-radius: 0;
}

.card-item.SingleItem .card-image img.card__img0 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    object-fit: cover;
}

main.Mobile.home-1.listing-details .card-item.TwoItem a .card-image {
    height: 117px;
    width: 100%;
    border-radius: 0;
}

main.Mobile.home-1.listing-details .card-item.TwoItem a .card-image img.card__img0 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    object-fit: cover;
}

main.Mobile.home-1.listing-details .GalleryPhotos .card-item.TwoItem .TwoImages .card-item {
    width: 100%;
}

main.Mobile.home-1.listing-details .GalleryPhotos .card-item.TwoItem .TwoImages .card-item .card-image.ShowCount {
    height: 80px;
    display: inline-block;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 0;
}

main.Mobile.home-1.listing-details .card-item.TwoItem .TwoImages .card-image {
    height: 80px;
    margin-top: 3px;
}

.DropDownBtn .dropdown:last-child button {
    border-right: 0;
}

.card-item.TwoItem {
    margin-bottom: 0px;
    position: relative;
    cursor: pointer;
    padding: 1.5px;
    text-decoration: none!important;
    flex: 1;
}

.TwoImages {
    display: flex;
    flex: 1;
}

.card-image.ShowCount {
    position: relative;
}

.ImagesCount {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    background: rgba(1, 1, 1, .7);
    color: #fff;
    z-index: 9;
}

main.Mobile.home-1.listing-details .card-item.TwoItem {
    display: flex;
    flex: 1;
    flex-direction: column;
}

main.Mobile.home-1.listing-details .card-item {
    margin-bottom: 0px;
    padding: 1.5px;
}

main.Mobile.home-1.listing-details .GalleryPhotos .card-item.TwoItem .TwoImages .card-item a {
    margin: 0;
    display: inline-block;
    width: 100%;
    height: 74px;
}

main.Mobile.GalleryListing .BrainhubCarousel__container, main.Mobile.GalleryListing .BrainhubCarousel {
    width: 100%;
    overflow: hidden;
    min-height: 100%;
    height: 100%;
    background: #000;
}

main.Mobile.home-1.listing-details section.single-listing-area .details.single-listing-wrap .comments-wrap ul.comments-list p.comment-content {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 10px;
}

.ImagesCount span {
    padding: 24px 0;
    display: inline-block;
}

/* ///////////////////////////////////////////////////////*/

.Mdirection {
    margin-top: 50px;
}

.m-direction {
    position: relative;
    height: 44px;
    background-color: #fff;
    display: grid;
    grid-template-columns: 60px auto 67px;
    align-items: center;
    padding: 0 10px;
    text-align: center;
}

.m-direction button.back {
    background: transparent;
    border: none;
    font-size: 14px;
    color: #666;
}

.back svg {
    top: -1px;
    position: relative;
    margin-right: 3px;
}

.m-direction .business-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
    font-size: 14px;
    color: #666;
    margin: 0 auto;
}

a.navigate {
    text-align: right;
    font-size: 14px;
}

main.Mobile div.gmnoprint div {
    width: 28px !important;
    height: auto !important;
}

main.Mobile .gmnoprint div button.gm-control-active {
    width: 28px !important;
    height: 28px !important;
}

main.Mobile div.gmnoprint div div {
    border: 1px solid rgb(230, 230, 230);
    margin: 0 !important;
}

/* /////////////////////////////////////////////////////////////////// */

section.breadcrumb-area.listing-detail-breadcrumb:after {
    display: none;
}

@media screen and (max-width: 360px) {
    main.Mobile .menu-full-width .logo {
        left: 60px;
    }
    .DropDownBtn .dropdown button {
        padding: .375rem 10px;
    }
    .d-btns ul li a.btn span {
        font-size: 13px;
    }
}