@import url('https://use.fontawesome.com/releases/v5.0.6/css/all.css');

img.img-thumbnail {
    border: 0;
}

a {
    text-decoration: none !important;
}

span.icon-element img.img-thumbnail {
    background: 0;
}
#sidebar-wrapper {
    min-height: 100vh !important;
    /* width: 100vw; */
    margin-left: -1rem;
    padding-top: 100px;
}
#page-content-wrapper {
    min-width: 0;
    /* width: 100%; */
    padding-top: 0;
}
.dashboard-area {
    margin: 30px;
}
.author-bio-img img {
    margin-right: 0px !important;
}
.remove-btn-bg {
    border: none;
    background-color: inherit;
    background-color: none;
    color: #59595a;
}
.remove-btn-bg:hover {
    color: #ff6b6b;
}
.remove-btn-bg:focus {
    outline: 0 !important;
}
.font-color-black {
    color: #000000;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
}
.safety1 .custom-checkbox input[type="checkbox"]:checked + label:before {
    background-color: #326d62;
    border-color: #326d62;
    z-index: 1;
}
.safety1 .custom-checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
    visibility: visible;
    z-index: 1;
}
.basic-multi-select .select__control {
    height: auto;
    min-height: auto;
    padding-left: 0;
}
.pac-container {
    z-index: 111111;
}
.pac-target-input {
    top: 30px !important;
    left: 10px !important;
}

.pull-right {
    float: right;
    right: 0;
    position: absolute;
}

.display-flex {
    display: flex;
}

.business-detail .y-page-link .gmaps .edit-content .dashboard-icon {
    margin-top: 10px;
}

.custom-legend .form-group {
    display: grid;
    grid-template-columns: auto auto;
}

.custom-legend legend {
    float: left;
    font-size: 16px;
    color: #333;
    width: 93%;
}

.custom-legend .form-control.border-0.p-0.h-auto {
    float: right;
    text-align: right;
}

.black-text {
    color: #333;
}

.edit-content-bootom {
    position: absolute;
    right: 0;
    bottom: 8px;
}

.card-image .badge-toggle.b-left {
    left: 5px;
    background-color: #000000;
    top: 5px;
    color: #fff;
}

.card-item .card__img.custom-img {
    width: 115px;
    height: 115px;
    border-radius: 10px;
}

.card-sub.card-custom {
    color: #2b273c;
    font-weight: normal;
    line-height: 21px;
}

/* .y-page-link .card-custom-1{
     margin-bottom: 3em; 
     margin-top: 3em; 
} */

.button-modal-footer > * {
    margin: 0.25rem;
}

span.dashboard-icon.margin-remove-top {
    margin-top: 10px;
}

.review-btns .r-button-reply {
    background: #5cd3b7;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    width: 115px;
    padding: 0;
    text-align: center;
    height: 40px;
    line-height: 40px;
    box-shadow: none;
    margin: 0 10px 0 10px;
}

.review-btns .r-button-reply:hover {
    color: #fff;
    background-color: #5cd3b7;
    border-color: #5cd3b7;
}

.review-btns .r-button-cancel {
    background: #eeeeef;
    color: #2b273c;
    font-size: 12px;
    font-weight: 600;
    width: 115px;
    padding: 0;
    text-align: center;
    height: 40px;
    line-height: 40px;
    box-shadow: none;
    margin: 0 10px 0 10px;
}

.review-btns .r-button-cancel:hover {
    color: #2b273c;
    background-color: #eeeeef;
    border-color: #eeeeef;
}

.search-input-1 {
    position: absolute;
    width: 795px;
    top: -120px;
}

.basic-multi-select.mt-3.mb-3.css-2b097c-container {
    margin-top: 0;
}

ul.BrainhubCarousel__dots li {
    width: 120px;
    display: inline-block;
    height: 100px;
    overflow: hidden;
}

.BrainhubCarousel__dots {
    display: inline-block;
}

ul.BrainhubCarousel__dots li button img {
    width: 100%;
    height: 100%;
}

.btn-box .r-button-d-block {
    background: #f11200;
    border-color: #f11200;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    width: 100%;
    height: 48px;
    padding-left: 24px;
    padding-right: 24px;
    text-transform: capitalize;
    transition: all 0.3s;
    box-shadow: 0 0 40px rgb(82 85 90 / 10%);
    position: relative;
    display: block;
    border-radius: 4px;
}

.btn-box .r-button-d-block:hover,
.btn-box .r-button-d-block:focus {
    background: #ea5555;
    border-color: #ea5555;
}

.modal-title.h4 {
    color: #000;
}

#style-1:-webkit-scrollbar-thumb:hover {
    background: #b30000;
}

#style-1:-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
}

#style-1:-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

#style-1:-webkit-scrollbar {
    width: 100%;
}

.uploads-photo .drag-and-drop-wrap.text-center .drag-and-drop-file .dropzone button.drag-drop-btn {
    padding: 0;
    border: 0;
    margin: 0;
    box-shadow: none;
    background: #f11200;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    width: 160px;
    text-align: center;
    line-height: 40px;
    border-radius: 5px;
}

.listing-load {
    position: relative;
    height: 100%;
}

.loader {
    width: 130px;
    height: 130px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.7);
    position: fixed;
    z-index: 9;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.form-control.c1.c2 {
    width: 100%;
}

main.home-1 .main-search-input .main-search-input-item input.react-autosuggest__input {
    width: 100%;
    line-height: inherit;
    padding: 12px 20px 12px 70px;
    font-size: 18px;
    color: #000000;
    border: 0;
    resize: none;
    font-weight: 500;
    transition: all 0.3s;
}

main.home-1 .main-search-input .main-search-input-item #react-autowhatever-1 {
    width: 100% !important;
    border-top: none !important;
    top: 40px !important;
    left: 0 !important;
}

.nav-listing-search .main-search-input-item input.react-autosuggest__input {
    border: 0;
    border-radius: 5px 0 0 5px;
    padding: 12px 0px 12px 15px;
    width: 310px;
    height: 45px;
    font-size: 14px;
    /* border-radius: 0;
    border-right: 1px solid #A3A3A3; */
}

.nav-listing-search .main-search-input-item #react-autowhatever-1 {
    width: 100% !important;
    border-top: none !important;
    top: 50px !important;
    left: 0 !important;
    overflow: auto;
}
.contact-form-action .form-group .form-icon {
    z-index: 1;
}

.aa-dropdown-menus {
    position: absolute;
    width: 100%;
    z-index: 9;
}

.aa-dropdown-menu {
    width: 100%;
    max-height: 208px;
    overflow: auto;
}

.container-fluid.nav-listing-cat {
    border-bottom: 1px solid #eeeeee;
    width: 100%;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 999;
}

.listing-list .sorted .dropdown button {
    background: transparent;
    border: 0;
    color: #000;
    padding: 0;
    margin: 0;
    font-size: 14px;
}

main.listing-list .sort-by .sorted {
    border-bottom: none;
    position: relative;
    top: -18px;
}

main.listing-list .sort-by {
    float: right;
}

.business-price ul.price label {
    color: #000000;
    position: relative;
    display: inline-block;
    margin: 0 20px 0 0px;
}

.business-price ul.price label span {
    display: inline-block;
    color: #2b273c;
    font-size: 14px;
    font-weight: 500;
    position: static;
}

.business-price ul.price label:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.business-price .heading h5 {
    margin-top: 20px;
    width: 173px;
    font-size: 16px;
    color: #000000;
}

.business-price ul.price span.circle {
    border-radius: 50%;
    position: absolute;
    top: 16px;
    left: -75px;
    display: block;
    width: 1rem;
    height: 1rem;
    background-color: #fff;
    border: #adb5bd solid 1px;
}

.business-price ul.price span.circle.active {
    color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    background-color: #007bff;
    background-repeat: no-repeat;
    background-size: auto;
}

button.react-apple-signin-auth-btn.react-apple-signin-auth-btn-dark.apple-auth-btn {
    background-color: #000;
    color: #fff;
    border-color: #fff;
}

main.dashboard-page .dashboard-area .y-page-link ul.covid-point form.av-valid {
    width: 60%;
}

main.dashboard-page .dashboard-area .y-page-link ul.covid-point form.amenties.av-valid {
    width: 100%;
}

main.dashboard-page .dashboard-area .y-page-link ul.price.p1 li .custom-legend fieldset.form-group {
    grid-template-columns: 300px auto;
}

main.dashboard-page .dashboard-area .y-page-link ul.price.p1 li .custom-legend .form-control.border-0.p-0.h-auto.is-untouched.is-pristine.av-valid {
    float: left;
    text-align: left;
}

.react-tagsinput input.react-tagsinput-input {
    width: 100%;
}

li.list-group-item .user-chat-meta {
    text-transform: uppercase;
}

.user-chat-meta .badge.success.badge-success {
    border-radius: 50%;
    top: 2px;
    right: 5px;
    font-weight: normal;
    width: 20px;
    height: 20px;
    line-height: 15px;
    background: #f11200;
}

.user-chat-meta .badge.default.badge-default {
    color: #ff9529;
    font-size: 18px;
    padding: 0;
}

.action-icons ul li button.fill-star {
    color: #ff9529;
}

button.btn.btn-primary {
    background: #f11200;
    border-color: #f11200;
    margin: 0 15px 0 0px;
}

main.dashboard-page .dashboard-area .y-page-link a.react-tagsinput-remove:before {
    content: " ×";
    font-size: 18px;
    padding: 5px;
    position: relative;
    top: 2px;
}

.card-custom-1.card .form-group select.av-valid.form-control {
    padding-left: 0;
}

.button-modal-footer {
    float: right;
}

.sidebar.section-bg.details-sidebar .sidebar-widget.certification .display-flex {
    border-bottom: 1px solid #eeeeef;
}

.sidebar.section-bg.details-sidebar .sidebar-widget.certification .certificate-title h2 {
    font-size: 16px;
    margin: 0;
    color: #2b273c;
}

.sidebar.section-bg.details-sidebar .sidebar-widget.certification .certificate-title p {
    font-size: 14px;
    color: #666;
}

.font-size-14px {
    font-size: 14px !important;
}

main.dashboard-page .dashboard-area .y-page-link .online-business .custom-checkbox input[type="checkbox"]:checked + label:before {
    background-color: #326d62;
    border-color: #326d62;
    z-index: 1;
}

main.dashboard-page .dashboard-area .y-page-link .online-business .custom-checkbox input[type="checkbox"]:not(:checked) + label:after,
main.dashboard-page .dashboard-area .y-page-link .online-business .custom-checkbox input[type="checkbox"]:checked + label:after {
    z-index: 1;
}

.online-business .react-autosuggest__container {
    margin-bottom: 20px;
    position: absolute;
    width: 96.4%;
    top: -32px;
    z-index: 1;
}
.online-business .react-autosuggest__container.react-autosuggest__container--open div#react-autowhatever-1 {
    margin-left: 15px;
    width: 98%;
    top: 40px !important;
}
.online-business {
    position: relative;
}
.online-business .react-autosuggest__container #react-autowhatever-1 {
    border: 0;
    top: auto;
    padding-left: 15px;
}
main.home-1 .list1 .card-item .like_class .activity-button-unlike {
    background: #ffffff;
    border: 0;
    color: #0056b3;
}
main.home-1 .list1 .card-item .like_class .activity-button {
    background: #ffffff;
    border: 0;
    color: #999;
}
main.home-1 .list1 .card-item .like_class .activity-button:hover {
    color: #007bff;
}
section.form-shared.login-page .input-box.login input.form-control {
    height: 50px;
}
.signup-desc.location p {
    font-weight: 500;
}
.signup-desc.step4.location {
    margin-bottom: 10px;
    width: 100%;
    margin-top: 50px;
    text-align: center;
}
.center {
    text-align: center !important;
}
.billing-content.BSignupForm .online-business .custom-checkbox input[type="checkbox"]:checked + label:before {
    background-color: #326d62;
    border-color: #326d62;
    z-index: 1;
}
.billing-content.BSignupForm .online-business .custom-checkbox input[type="checkbox"]:not(:checked) + label:after,
.billing-content.BSignupForm .online-business .custom-checkbox input[type="checkbox"]:checked + label:after {
    z-index: 1;
}
.billing-content.BSignupForm .online-business {
    float: right;
}
main.BusinessHome .user-side.login {
    height: auto;
}
main.BusinessHome .user-side.login a {
    width: 160px;
    display: inline-block;
}
main.BusinessHome .user-side.login span {
    position: relative;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
    display: inline-block;
    top: 10px;
    margin: 0 0px 0 10px;
}
main.BusinessHome .user-area {
    /*padding: 20px 0;*/
}
.BLanding.BNavabar.bLanding .TopHeader {
    height: 72px;
}
.BLanding.BNavabar.bLanding .TopHeader ul.business {
    height: 100%;
    display: flex;
    align-items: center;
}
.bottom-btn a.btn.btn-default {
    background: #f11200;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    width: 230px;
    height: 54px;
    line-height: 44px;
}
.DeleteReviews button {
    margin: 0 0 0 10px;
    background-color: transparent;
    border: 0;
}
.TrashIcon button {
    width: 30px;
    height: 30px;
    border: 1px solid #eeeeef;
    display: inline-block;
    text-align: center;
    border-radius: 2px;
}
.TrashIcon button:hover {
    background: #f11200;
    border-color: #f11200;
    color: #ffffff;
}
.BuyerEmail .button-modal-footer {
    float: left;
}
.BuyerEmailList.Notification {
    display: block;
}
.BuyerContent.FriendsManage .BuyerPagesHeading .form-group {
    margin: 0;
}
.BuyerContent.FriendsManage .search-friend {
    border: 1px solid #a5a5a5;
    box-sizing: border-box;
    border-radius: 3px;
}
.EmailAdd.friend {
    margin: 0;
}
.EmailAdd.friend button {
    border-radius: 0px 3px 3px 0px;
    height: 38px;
}
.BuyerEmailNoti .EmailAdd.PhoneNo.SaveBtn a {
    background: transparent;
    color: #007bff;
}
.TermsContent p div {
    color: #000;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 500;
}
.main-menu-content.BHome.TP {
    right: 0;
    position: absolute;
    top: 24px;
}
.main-menu-content.BHome.TP li a {
    font-size: 16px;
    font-weight: 500;
    padding: 15px 10px;
}
main.BusinessHome.TermsOfService .logo.listing img {
    width: auto;
}
main.BusinessHome.TermsOfService .main-menu-content.BHome.TP li a.active {
    border-bottom: 6px solid #ffffff;
    padding-left: 0;
    padding-right: 0;
}
.display-block {
    display: block;
}
.none {
    display: none !important;
}
.display-none {
    display: none;
}
.user_review button {
    margin:0;
}
.like_class button.btn.btn-link p {
    display: contents;
}
.ml-20 {
    margin-left: 20px;
}
.cities.recent .user_review {
    display: flex;
}
.like_class p {
    display: block;
    position: absolute;
    margin-left: 40px;
    margin-top: 8px;
    color: #007bff;
}
.user_review .useful {
    display: block;
    position: absolute;
    margin-left: 45px;
    margin-top: 10px;
}
.user_review .smile p {
    display: block;
    position: absolute;
    margin-left: 45px;
    margin-top: 10px;
}
.user_review .cool p {
    display: block;
    position: absolute;
    margin-left: 45px;
    margin-top: 10px;
}
section.k-class.footer-area.BizFooter .col-lg-3.col-md-3.col-sm-3.column-td-6.logo img {
    width: 100%;
    height: auto;
}
.single-listing-wrap.details .saved-friend {
    display: inline-block;
}
.single-listing-wrap.details .saved-friend .comment {
    display: block;
    margin-bottom: 0;
}
.single-listing-wrap.details .saved-friend .comment img.avatar__img {
    width: 40px;
    height: 40px;
    border: 0;
}
.single-listing-wrap.details .saved-friend ul.comments-list li {
    display: inline-block;
}
.single-listing-wrap.details .saved-friend ul.comments-list li:nth-of-type(2) {
    position: relative;
    left: -25px;
}
.saved-friend ul.comments-list pre {
    margin-bottom: 0;
    overflow: inherit;
    margin-left: 9px;
    font-weight: 700;
    font-size: 14px;
    color: #000000;
}
.saved-friend ul.comments-list pre {
    margin-bottom: 0;
    overflow: inherit;
    margin-left: -10px;
    font-weight: 700;
    font-size: 14px;
    color: #000000;
}
main.home-1 .user-info-details span.photos {
    color: #ff4f00;
    font-size: 18px;
}
section.single-listing-area.padding-top-35px {
    color: #333;
}
.write-review .review-button {
    background: #f11200;
    color: #fff;
    border: 0;
}
svg.svg-inline--fa.fa-star,
svg.svg-inline--fa.fa-star-half-alt {
    border-radius: 3px;
    margin: 0 3px 0 0;
    text-align: center;
    vertical-align: bottom;
    padding: 0 5px 0 5px;
    line-height: 17px;
    background: #ff4f00;
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 25px;
}
.font-size-12px {
    font-size: 12px;
}
main.dashboard-page .y-page-link a.video {
    font-size: 14px;
    line-height: 21px;
    color: #326d62;
}

ul.list-items li a span.dashboard-icon img {
    width: 18px;
}
.fix-sidebar .icon img {
    width: 18px;
}

.VideoBox img {
    width: 550px;
}
.comment.listing-details .rating-rating div {
    display: inline-block;
}
.sidebar.section-bg.details-sidebar .sidebar-widget.certification ul li a {
    color: #000000;
}
.sidebar.section-bg.details-sidebar .sidebar-widget.certification ul li {
    height: auto;
}
.sidebar.section-bg.details-sidebar ul li p,
.sidebar.section-bg.details-sidebar ul li span {
    margin: -10px 10px -10px 10px;
    line-height: 20px;
}
.sidebar.section-bg.details-sidebar ul li .custom-color-326D62 {
    color: #326d62;
}
main.dashboard-page.BuyerProfile.BuyerReviews.UserReviews .ReviewsBtn .use button.btn.btn-none p {
    display: block;
    position: absolute;
    /* margin-left: 79px; */
    margin-top: 4px;
    color: #999999;
    font-size: 12px;
    font-weight: 700;
    right: 5px;
    top: 3px;
}
main.dashboard-page.BuyerProfile.BuyerReviews.UserReviews .ReviewsBtn button.btn.btn-none {
    position: relative;
}
main.dashboard-page.BuyerProfile.BuyerReviews.UserReviews .ReviewsBtn button.btn.btn-none.active {
    background: #007bff;
    color: #fff;
    border-color: #007bff;
}
main.dashboard-page.BuyerProfile.BuyerReviews.UserReviews .ReviewsBtn button.btn.btn-none.active p span {
    color: #fff;
}
main.dashboard-page.BuyerProfile.BuyerReviews.UserReviews .ReviewsBtn button.btn.btn-none p.useful {
    position: absolute;
    right: 0;
    top: 7px;
}
.single-listing-wrap.details .FriendCount img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
}
main.signup-page.BizSignUp p {
    font-weight: normal;
    margin-top: 25px;
    font-size: 15px;
}
main.signup-page.BizSignUp h5 {
    font-weight: 600;
    font-size: 32px;
    color: #000000;
}
main.signup-page.BizSignUp .bottom-btn.step-1 {
    text-align: left;
    margin-left: 35px;
}
.signup-step-2 {
    padding-left: 25px;
    color: #000;
}
.sign-up-image .step-3 p {
    font-size: 16px;
    color: #000;
}
main.signup-page.BizSignUp .signup-desc.step-3 p {
    font-size: 16px;
}
.about.step-4 h1 b {
    color: #f11200;
}
main.signup-page.BizSignUp p.step-4-login-text {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}
.share-footer.modal-footer button.btn.btn-primary {
    width: 150px;
}
.share-footer.modal-footer {
    text-align: center;
    display: block;
    margin: 0 auto;
}
.form-group.share-link span {
    position: absolute;
    left: 10px;
    top: 6px;
    border-right: 1px solid #ccc;
    width: 30px;
    height: 26px;
}
.form-group.share-link {
    position: relative;
}
.form-group.share-link input.form-control {
    padding-left: 50px;
}
.margin-left-20px {
    margin-left: 20px;
}
main.dashboard-page.details-info .safety.food-types {
    margin-top: 0;
}
.user-photo-section-meta p.user-photo {
    color: #000;
    margin-bottom: 20px;
}
.user-photo-section-meta {
    margin-top: 20px;
}
ul.comments-list li .rate-business p.comment-content {
    margin: 0 0 5px;
}
main.dashboard-page.BuyerProfile.BuyerReviews.UserReviews ul.uploads.user-2 .upload-photos button.remove-btn-bg {
    margin: 0;
    padding: 0;
}
main.dashboard-page.BuyerProfile ul.uploads .upload-photos button.remove-btn-bg:hover {
    color: #0073bb;
}
main.dashboard-page.BuyerProfile ul.uploads .upload-photos button.remove-btn-bg img {
    margin-right: 5px;
}
.collapse.show .card-custom-1.card label.form-check-label {
    font-size: 14px;
}
.rating-rating.rating-detail.landing-2 span span span img.icon {
    width: 17px;
}
.rating-rating.rating-detail.landing-3 span span span img.icon {
    width: 25px;
}
ul.BottomMenu li button {
    font-weight: normal;
    font-size: 12px;
}
.info-list ul.BottomMenu li:hover button {
    color: #ff6b6b;
}
.info-list li button.remove-btn-bg {
    border: none;
    background-color: inherit;
    padding: 0;
    color: #808996;
}
.react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
    height: 400px;
    overflow: auto;
}
.BuyerContent.BuyerLocation.BuyerFindFriend .BuyerEmailList {
    grid-template-columns: auto 200px;
    padding-bottom: 15px;
}
.BuyerContent.BuyerLocation.BuyerFindFriend input.btn.btn-link {
    padding: 0;
}
.info-list-2 .BuyerEmail {
    position: absolute;
    border: 0;
    bottom: 60px;
}
.info-list-3 .BuyerEmail {
    margin-top: 40px;
    border: 0;
    bottom: 0;
}
.info-list-2 .BuyerEmail .BuyerPagesHeading,
.info-list-3 .BuyerEmail .BuyerPagesHeading {
    font-size: 14px;
    font-weight: 600;
}
.BuyerContent.BuyerLocation.BuyerFindFriendFB .BuyerEmailList {
    grid-template-columns: auto;
}
.BuyerContent.BuyerLocation.BuyerFindFriendFB .BuyerPagesHeading {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
}
.BuyerContent.BuyerLocation.BuyerFindFriendFB .EditLocationForm1 label {
    font-size: 12px;
    color: #000;
    padding: 0;
}
.login.section {
    display: grid;
    grid-template-columns: 80px 80px;
    padding: 7px 0 10px;
}
.login.section .login {
    width: 80px;
    height: 40px;
    border: 1px solid #ccc;
    text-align: center;
    padding: 7px 0;
    border-radius: 5px;
}
.login.section .login:hover {
    background: #f0f0f0;
}
.login.section .login a {
    background: transparent;
}
.login.section .signup {
    margin-left: 10px;
    width: 80px;
    height: 40px;
    background: #f11200;
    border: 1px solid #f11200;
    text-align: center;
    padding: 7px 0;
    border-radius: 5px;
}
.login.section .signup a {
    color: #fff;
    background: transparent;
}
main.home-1 .section-heading h4.sec__title {
    padding-top: 0;
}
.like_class button.btn.btn-link.default {
    color: #999;
}
.like_class p.default {
    color: #999;
}
main.home-1 .user_review p span.CountNumber.default {
    color: #999;
}
main.home-1 .user_review button.btn.btn-link.card__img.default , main.home-1 .user_review a.btn.btn-link.card__img.default {
    color: #999;
}
main.home-1 .list1 p.descs {
    font-size: 14px;
    font-weight: normal;
}
.card-item.card-listing.d-flex.listing-page:hover {
    box-shadow: 0 0 20px rgb(10 0 0 / 15%);
}
.single-listing-wrap.details ul.list-items li {
    color: #333;
}
main.listing-details .ReviewsBtn button.btn.btn-none p.useful1 {
    position: absolute;
    right: 0;
    top: 7px;
}
section.single-listing-area .ReviewsBtn button.btn.btn-none p.funny {
    position: absolute;
    right: 0;
    top: 7px;
}
section.single-listing-area .ReviewsBtn button.btn.btn-none p.cool {
    position: absolute;
    right: 0;
    top: 7px;
}
section.single-listing-area ul.comments-list li {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 30px;
    padding-top: 20px;
}
main.listing-details .ReviewsBtn button.btn.btn-none.active {
    background: #007bff;
    color: #fff;
    border-color: #007bff;
}
main.listing-details .ReviewsBtn {
    margin-top: 20px;
    display: inline-flex;
}
main.listing-details .ReviewsBtn button.btn.btn-none {
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 700;
    color: #999999;
    margin: 0 15px 0 0;
    min-width: 100px;
    text-align: left;
    position: relative;
}
main.listing-details .ReviewsBtn button.btn.btn-none span {
    margin: 0 5px 0 0px;
}
main.BuyerProfile .sidebar.section-bg.mt-2.mb-4.BuyerProfile .sidebar-widget .list-items li a span {
    width: 20px;
    text-align: left;
}
main.dashboard-page.BuyerProfile .Buyer-Reviews.overView {
    display: block;
}
main.dashboard-page.BuyerProfile .Buyer-Reviews.overView p.comment-content {
    width: 100%;
}
main.dashboard-page.BuyerProfile.BuyerReviews.UserReviews .Buyer-Reviews.overView .BuyerContent {
    padding-top: 0;
}
.amount fieldset.form-group legend {
    font-size: 1rem;
}
.custom-legend1.legend-2 fieldset.form-group {
    display: block;
    position: relative;
    width: 123px;
    padding: 0;
    float: right;
    margin: 0;
}
.custom-legend1.legend-2 {
    margin: 10px 0;
}
.key-field.key-field1 {
    width: 100%;
    margin: 10px 0;
}
.service p.author__meta {
    font-size: 16px;
    color: #2b273c;
    border-bottom: 4px solid #ff1111;
    display: inline-block;
    border-radius: 2px;
}
.find-friend-search.SearchArea .search-friend.form-control {
    font-size: 16px;
    border: 1px solid #a5a5a5;
    box-sizing: border-box;
    border-radius: 3px;
}
.find-friend-search.SearchArea #react-autowhatever-1 {
    top: 39px;
}
.find-friend-search.SearchArea ul.react-autosuggest__suggestions-list li a {
    color: #808996;
}
.find-friend-search.SearchArea ul.react-autosuggest__suggestions-list li:hover a {
    color: #000000;
}
.find-friend-search.SearchArea ul.react-autosuggest__suggestions-list li:hover {
    background: #fff;
    color: #333;
    cursor: pointer;
}
.bottom-heading.main-page p.main-p.sec__desc {
    display: inline-block;
}
main.home-1 .logo img {
    width: 254px;
}
li.BrainhubCarouselItem img.img-example {
    max-width: 633px;
    width: auto;
    height: auto;
    max-height: 550px;
}
.send-message-business .modal-header {
    padding-top: 30px;
    border-bottom: 0;
}
.send-message-business .modal-title.h4 {
    font-weight: 900;
    font-size: 28px;
}
.send-message-business .close {
    font-size: 25px;
    font-weight: 300;
    padding-top: 25px;
    padding-right: 25px;
    color: #333333;
}
.send-message-business .form-group label {
    color: #000;
    font-weight: normal;
    font-size: 16px;
}
.send-message-business .messgae-share {
    font-weight: 500;
    font-size: 14px;
    color: #000;
    padding: 2px;
}
.send-message-business .form-group textarea#message {
    border: 1px solid #989898;
    box-sizing: border-box;
    border-radius: 3px;
    height: 100px;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-top: 5px;
}
.send-message-business .modal-footer {
    border-top: 0;
    justify-content: flex-start;
    padding-left: 0;
    padding-top: 5px;
    display: block;
}
.send-message-business .modal-footer p.notes {
    font-size: 12px;
    color: #666666;
}
.card-third {
    display: inline-block;
    width: 100%;
    border: 1px solid #e6e6e6;
    margin-bottom: 5px;
    border-radius: 3px 3px 0px 0px;
}
.cities.recent .list1 .like_class {
    padding-left: 10px;
    height: 35px;
}
.send-message-business .modal-content {
    padding: 0 1rem;
}
.send-message-business .modal-lg {
    margin: 5.75rem auto;
}
.cities.recent.BuyerPhotos .part {
    height: 130px;
}
.cities.recent.BuyerPhotos .part img {
    height: 100%;
}
.card-image .badge-toggle.b-right {
    right: 5px;
    background-color: #000000;
    top: 5px;
    color: #fff;
}
main.dashboard-page.BuyerProfile .user-info-details {
    top: 0;
    bottom: auto;
    background: transparent;
}
main.dashboard-page.BuyerProfile ul.comments-list.listing-gallery-p.padding-top-10px .comment.listing-details .comment-body .meta-data span.comment__author {
    color: #000000;
}
main.dashboard-page.BuyerProfile ul.comments-list.listing-gallery-p.padding-top-10px .comment.listing-details .comment-body {
    margin: -4px 0 10px 0;
}
.user-info-details p.description {
    color: #000;
    font-size: 14px;
    font-weight: normal;
}
.user-info-details p.date {
    color: #000;
    margin-top: 20px;
}
main.listing-details ul.comments-list.listing-gallery-p.padding-top-10px .comment.listing-details .comment-body .meta-data span.comment__author {
    color: #000;
}
main.listing-details .user-info-details {
    top: 0;
    bottom: auto;
    background: transparent;
}
main.listing-details .modal-content .slider-content .btn-box {
    text-align: right;
    display: inline-block;
    position: absolute;
    top: -5px;
    right: 15px;
    z-index: 1;
}
.cities.recent .ReviewsWrote .m-p,
.cities.recent .list1 .m-p,
.cities.recent .ReviewsWrote p.descs {
    color: #333;
}
main.directions .filter .form-group {
    margin-top: 1rem;
}
main.directions .filter {
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
}
main.directions .generic-header {
    display: inline-block;
    z-index: 1;
    background: #fff;
    width: 335px;
    margin: 15px 0px 0 15px;
}
.filter p {
    font-weight: 600;
    margin-bottom: 6px;
    font-size: 16px;
    color: #d32323;
}
main.directions ul.generic-nav li button {
    padding: 0 5px;
}
.media.m-round.destination {
    padding: 0;
}
main.directions ul.generic-nav {
    border-bottom: 1px solid #ccc;
    padding-bottom: 0;
}
.media.m-round.destination img {
    height: 18px;
    width: auto;
    display: block;
}
.media-body.m-body.destination-name h4.media-heading.m-h4 {
    margin-bottom: -8px;
}
address.destination-address {
    color: #333;
    font-size: 12px;
    margin-bottom: 5px;
}
main.directions .get-direction {
    margin: 10px 0 0 0;
    width: 112px;
    display: block;
    background: #f11200;
    color: #fff;
    font-size: 13px;
}
.direction-suggestion {
    margin: 6px 0;
    min-height: auto;
    max-height: 240px;
    overflow: auto;
}
.direction-suggestion p:first-child {
    font-weight: 600;
    font-size: 16px;
    color: #d32323;
}
.direction-suggestion .media.m-round {
    padding: 5px 0 0 0;
}
.direction-suggestion img.media-object {
    height: 18px;
    width: auto;
}
.direction-suggestion .media-body.m-body {
    margin: 0;
}
p.distance-calculate.border-bottom {
    color: #333;
    font-size: 12px;
}
main.directions table tbody tr td {
    font-size: 12px;
    color: #333;
}
main.directions .form-group label {
    font-weight: bold;
    color: #333;
    font-size: 14px;
}
.sidebar.section-bg.details-sidebar ul li.justify-content-between.b-address address {
    line-height: normal;
    padding-left: 10px;
    font-weight: normal;
    font-size: 14px;
    color: rgba(117, 114, 128, 1);
    margin-top: -15px;
    margin-bottom: 10px;
}
.sidebar.section-bg.details-sidebar ul li.justify-content-between.b-address {
    height: auto;
}
.safety.safety-measure {
    margin-top: 0;
}
.main-menu-content .D-Logo {
    /* padding: 15px 0px; */
    display: flex;
    align-items: center;
    height: 72px;
}
.main-menu-content nav {
    height: 100%;
}
.height-240 {
    height: 240px;
    overflow: auto;
}

.height-400 {
    height: 400px;
    overflow: auto;
}


.add-caret {
    position: relative;
}
.chat-module .add-caret label.caret {
    position: absolute;
    margin: 0 auto;
    top: 28px;
    margin-left: -55px;
    z-index: 1;
    color: #333;
    display: inline;
}
.main-menu-content li.LogOut.seller {
    width: 141px;
    text-align: center;
}
.sidebar.listing-page .sidebar-widget:nth-child(2) {
    padding-bottom: 20px;
}
main.dashboard-page.BuyerProfile .main-menu-content.listin_nav {
    height: auto;
}
button.btn.btn-link.group {
    padding: 0;
    position: relative;
    margin-top: 20px;
}
button.btn.btn-link.group:focus {
    text-decoration: none;
}
.group-participant {
    display: inline-block;
    /* border-bottom: 1px solid #999; */
    width: 100%;
    padding: 0;
}

ul.social-profile.group-participant li {
    margin: 0;
    color: #000;
    font-size: 12px;
    font-weight: normal;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #eee;
    padding: 4px 0 4px 10px;
    float: left;
}

li.more-button p {
    display: inline-block;
}
p.ShopAddress.mb-10 {
    margin-bottom: 10px !important;
}
main.listing-list .MapArea .MapBox div.gmnoprint div {
    width: auto !important;
}
main.listing-list .MapArea .MapBox .gm-style-mtc {
    width: auto !important;
}
section.dashboard-area.business-detail .card-custom-1.card .form-group select.av-valid.form-control {
    padding-left: 10px;
    width: 100%;
    line-height: 1;
    background: url(https://katika-images.s3.amazonaws.com/category/br_down1621253370.webp) no-repeat right 10px center #fff;
    -webkit-appearance: none;
    background-size: 1.2%;
}
.card-custom-2.card .card-body {
    padding-left: 0;
}
.card-custom-2.card button.btn.btn-primary {
    margin: 0;
}
input.react-tagsinput-input.tag12 {
    width: 125px;
}
.tag-custom .react-tagsinput {
    height: calc(1.5em + 0.75rem + 2px);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 3px;
}
input.react-tagsinput-input.tag12 {
    width: 160px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2;
    color: #495057;
    margin: 0;
    padding: 0;
    padding-left: 10px;
    font-family: "Poppins";
}
.tag-custom span.react-tagsinput-tag {
    height: auto;
    padding: 1px 10px;
    font-family: "Poppins";
}
.insta-gram-photo .modal-title.h4 {
    font-weight: 600;
    font-size: 18px;
}
.insta-gram-photo .close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
    opacity: 1;
}

.insta-gram-photo .modal-title.h4 p {
    font-weight: normal;
    font-size: 12px;
    padding-top: 5px;
}

.insta-gram-photo .close {
    font-weight: 400;
    padding-top: 25px;
    padding-right: 25px;
}

.insta-gram-photo .modal-body{
    height: 400px;
    overflow: auto;
    padding-bottom: 0;
}
.InstaPhoto{
    padding-right: 0;
    display: inline-block;
}

.InstaPhoto .card-item.Iphoto-card {
    border: 0;
}

.InstaPhoto .card-item.Iphoto-card img{
    width: 120px;
    height: 120px;
}

.InstaPhoto .Iphoto-card .custom-checkbox input[type="checkbox"]:not(:checked) + label, .InstaPhoto .Iphoto-card .custom-checkbox input[type="checkbox"]:checked + label {
    position: absolute;
    padding-left: 0;
    width: 100%;
    font-size: 0;
    height: 120px;
}

.InstaPhoto .Iphoto-card .custom-checkbox input[type="checkbox"]:checked + label:after {
    opacity: 0;
    visibility: visible;
}

.InstaPhoto .card-item.Iphoto-card .card-image:after {
    display: none;
}
.InstaPhoto .card-item.Iphoto-card .custom-checkbox {
    width: 120px;
    height: 120px;
}
.InstaPhoto.SelectPrice {
    border: 0;
    border-radius: 0;
}

main.dashboard-page.details-info.in-loader .loader {
    z-index: 9999;
}

.suggest:hover { 
    background: #f11200;
    color: #fff;
    cursor: pointer;
}

.suggest { 
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
}

#zuck-modal-slider-stories-react .story-viewer.muted.with-back-button.viewing .head  .item-preview{
    width: 32px;
    height: 32px;
}
#zuck-modal-slider-stories-react .story-viewer.muted.with-back-button.viewing .head {
    width: 366px !important;
    top: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

#zuck-modal-slider-stories-react .story-viewer.muted.with-back-button.viewing .head .left .info {
    display: inline-flex;
    vertical-align: middle;
}

#zuck-modal-content .story-viewer .head {
    width: 375px !important;
    margin: 0 auto;
}

#zuck-modal-content .story-viewer .slides-pointers {
    width: 375px !important;
    top: 0;
    margin: 0 auto;
    left: 0  !important;
    right: 0 !important;
}

#zuck-modal-content #zuck-modal-slider-stories-react .story-viewer.muted.viewing.with-back-button .slides-pointers {
    width: 375px;
    top: 0;
    left: 0;
    z-index: 100020;
    right: 0;
    margin: 0 auto;
}

div#zuck-modal #zuck-modal-content .story-viewer .slides .item > .media {
    height: 90%;
    width: 372px;
}

#zuck-modal-content .story-viewer .head .time {
    padding-left: 10px;
}
.slides-pagination {
    position: absolute;
    width: 470px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 11;
    cursor: pointer;
}

#zuck-modal-content .story-viewer .slides-pagination span.previous {
    left: 0;
}

#zuck-modal-content .story-viewer .slides-pagination span.next {
    right: 0;
}

.stories.carousel .story > .item-link > .info {
    position: absolute;
    left: 0;
    bottom: 10px;
    color: #fff;
    font-size: 16px;
    right: 0;
    font-weight: 500;
    white-space: normal;
    width: 100px;
    text-align: left;
    margin: 0 auto;
}

main.dashboard-page.BuyerProfile.BuyerReviews.BuyerStory #stories-react {
    white-space: unset;
    overflow: unset;
}

main.dashboard-page.BuyerProfile.BuyerReviews.BuyerStory .reviews-tabs {
    max-width: 510px;
    margin-top: 5px;
}

main.dashboard-page.BuyerProfile.BuyerReviews .BuyerContent .story.custom-story {
    width: 170px;
    margin: 0px 2px 60px 0;
    max-width: 170px;
    height: 300px;
    padding: 1px; 
}

.box.form-group {
    position: absolute;
    margin: 0 auto;
    bottom: 15px;
    left: 0;
    right: 0;
    display: inline-flex;
    width: 360px;
    z-index: 99999;
}

button.button-zuck.fa {
    background: transparent;
    border: 0;
    padding-left: 6px;
    padding-top: 5px;
}

.button-zuck.fa:before {
    color:#fff;
    content: url("https://katika-images.s3.amazonaws.com/category/Vector%20%281%291621511115.png");
}

input.form-control.box-zuck {
    border: 1px solid #FFFFFF;
    color: #fff;
    box-sizing: border-box;
    border-radius: 30px;
    background: transparent;
}

#zuck-modal-content, #zuck-modal-content .story-viewer, #zuck-modal-content .story-viewer > .slides, #zuck-modal-content .story-viewer > .slides > * {
    width: 100% !important;
   
}

#zuck-modal .slider {
    width: 100% !important;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0 !important;
    position: absolute;
    right: 0;
    margin: 0 auto;
}
#zuck-modal.with-cube .slider > .viewing {
    left: 0 !important;
    transform: translateZ(50vw);
}

.mute-button {
    position: absolute;
    z-index: 6666666;
    width: 366px;
    top: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.mute-button p {
    background: transparent;
    border: 0;
    padding-top: 5px;
    color: transparent;
    width: 20px;
    height: 25px;
    position: absolute;
    right: 25px;
    top: 20px;
}

.mute-button p:first-child:before {
    color:#fff;
    content: url("https://katika-images.s3.amazonaws.com/category/Group1621599335.png");
}

.mute-button p:last-child:before {
    color:#fff;
    content: url("https://katika-images.s3.amazonaws.com/category/voulme-open1622111695.png");
}

.pause-button.middle p {
    background: transparent;
    border: 0;
    padding-top: 5px;
    color: transparent;
    width: 20px;
    height: 25px;
}
.pause-button.middle {
    position: absolute;
    z-index: 6666666;
    width: 366px;
    top: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.pause-button.middle p {
    position: absolute;
    right: 0px;
    top: 20px;
}

.pause-button.middle p:first-child:before {
    color:#fff;
    content: url("https://katika-images.s3.amazonaws.com/category/Vector%20%282%291621597067.png");
}

.pause-button.middle p:last-child:before {
    color:#fff;
    content: url("https://katika-images.s3.amazonaws.com/category/pause1621598496.png");
}




/* #zuck-modal-content, #zuck-modal-content .story-viewer, #zuck-modal-content .story-viewer > .slides, #zuck-modal-content .story-viewer > .slides > * {
    width: 300px !important;
   
}

#zuck-modal .slider{
    left: 0  !important;
    position: static !important;
    width: auto !important;
}

#zuck-modal.with-cube .slider > .viewing {
    left: 0 !important;
}

#zuck-modal .slider > * {
    width: 300px !important;
} */




.sb-sidenav-toggled #wrapper main.chat-module #sidebar-wrapper {
    /* margin-right: -15rem; */
    margin-left: 0;
    right: -1200px;
    width: 0;
}
 #wrapper main.chat-module #sidebar-wrapper {
    margin-right: 0;
    margin-left: 0;
    position: relative;
    right: 0px;
    width: auto;
    min-height: 685px !important;
}
.he .chat-user-info {
    border-left: 0;
    width: 100%;
    margin: 0;
}
.he {
    flex-direction: column;
    width: 100%;
}
.he .chat-user-info .user-pic img {
    width: 32px;
}

/* .group-info-1 {
    width: 285px;
} */


div#accordionExample1{
    border: 0;
}
.group-info-1 .accordion-item .card:not(:last-of-type), .accordion-item .card:not(:first-of-type) {
    border-radius: 0;
    border: 0;
}

.group-info-1 .accordion-item .card .btn i{
    background: transparent;
    color: #000;
    box-shadow: none;
    text-align: right;
}

.group-info-1  .accordion-item .card .btn {
    color: #000;
    padding: 0px 10px;
    font-weight: 500;
    font-size: 12px;
}

.toggle-button {
    display: block;
    float: right;
}

.card.grey.lighten-3.chat-room .card-body {
    padding: 0 !important;
}

.grey.lighten-3.chat-room .search {
    width: 100%;
}

p.message.reply {
    background: #007AFF;
    border-radius: 50px;
    color: #fff;
}
p.message {
    margin-left: 80px;
    background: #F0F0F0;
    border-radius: 50px;
    padding: 7px 15px 7px 15px;
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
}

.caht-option-area.arrow {
    display: inline-block;
}
.auto-search.back-arrow {
    height: auto;
}
.Chat.chat-arrow-filter {
    display: inline-flex;
}

.chat-arrow-filter button.chat-back-arrow {
    background: transparent;
    border: 0;
    padding-right: 15px;
}

p.text-muted.active-people {
    font-size: 12px;
    color: #999999;
    margin-top: 10px;
}

.auto-search.people.back-arrow {
    border-bottom: 0;
}

.chat-highlight {
    background-color: yellow;
}

span.highlight {
    background-color: yellow;
    border-radius: .125em;
}
    

ul.list-group .chat-message small.d-block.text-center.text-muted {
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
}

main.chat-module .name-user strong.primary-font {
    font-weight: 500;
    font-size: 10px;
    color: #999999;
}

.caht-option-area {
    display: grid;
    grid-template-columns: 70% 30%;
    padding: 10px;
}
.Chat {
    font-weight: bold;
    font-size: 24px;
    color: #000000;
}

.chat-option {
    text-align: end;
    display: grid;
    grid-template-columns: 50% 50%;
}

.chat-option .filter, .new-chat{
    background: #E7ECF1;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    text-align: center;
    padding-top: 5px;
    color: #000000;
}

.chat-search-area #react-autowhatever-1 {
    border: 0;
    width: 100%;
    top: 30px;
}

.chat-search-area input.search__input {
    background: #E7ECF1;
    border-radius: 90px;
    padding: 10px;
    border: 0;
    font-size: 14px;
    width: 100%;
    height: 30px;
    opacity: 1;
    z-index: unset;
    color: #999999;
    padding-left: 30px;
}

.chat-search-area span {
    width: 12px;
    height: 12px;
    transform: translate(0%, 0%);
    transition: width 0.4s 0.4s;
    background-image: url(https://katika-images.s3.amazonaws.com/category/eva_search-fill1621927606.png);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 20px;
    z-index: 1;
    top: 9px;
}

.chat-search-area {
    padding: 0 10px;
    position: relative;
}

.caht-option-area .dropdown button img {
    height: auto;
    width: auto;
}

.chat-option .filter .dropdown button , .chat-option .new-chat .dropdown button  {
    border: 0;
    width: auto;
    background: transparent;
    padding: 0;
    margin: 0;
}
.chat-option .filter .dropdown button::after , .chat-option .new-chat .dropdown button::after {
    display: none;
}

.chat-option ul.OptionMenu li {
    padding: 10px;
    margin: 0 5px;
}
.chat-option .dropdown-menu {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 4px rgb(0 0 0 / 10%);
    border-radius: 6px;
    width: 238px;
}
.chat-option ul.OptionMenu li button {
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px !important;
    vertical-align: bottom;
}

.chat-option ul.OptionMenu li svg {
    font-size: 24px;
}

.chat-option ul.OptionMenu li button {
    font-size: 16px;
    font-weight: 400;
}

.chat-option ul.OptionMenu li img {
    height: auto;
    width: auto;
}

.create-group-modal .modal-header {
    text-align: center;
    display: block;
    position: relative;
    border: 0;
}

.create-group-modal button.close {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 36px;
    height: 36px;
    background: #E4E6EA;
    border-radius: 50%;
    color: #fff;
    padding: 0;
}

.create-group-modal .modal-title.h4 {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}

.group-photo {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    background: #C4C4C4;
    margin-bottom: 20px;
}

.group-photo svg {
    font-size: 100px;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    color: #000000;
}

button.btn.btn-default.add-group-photo {
    background: #EEEEEF;
    border-radius: 4px;
    margin: 0 auto;
    display: block;
    margin-top: 17px;
    font-size: 12px;
    color: #000000;
}

.create-group-modal .form-group .form-control {
    color: #000;
    font-size: 14px;
    font-weight: 400;
}

.create-group-modal .basic-multi-select .select__placeholder {
    color: rgba(153, 153, 153, 1) !important;
    font-size: 14px;
    font-weight: 400;
}
/* .create-group-modal .modal-footer{
     padding-right: 0;
}

.create-group-modal .modal-footer button {
    margin-right: 0;
} */

.direct-modal .modal-header {
    text-align: center;
    display: block;
    position: relative;
}

.direct-modal .modal-body {
    padding: 1rem 0 1rem 0;
}

.direct-modal button.close {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 36px;
    height: 36px;
    background: #E4E6EA;
    border-radius: 50%;
    color: #fff;
    padding: 0;
}

.direct-modal .modal-title.h4 {
    font-weight: bold;
    font-size: 20px;
    color: #000000;
}

.direct-modal  .horizontal-line {
    background: #EAEAEA;
    margin-top: 10px;
    height: 30px;
    margin-bottom: 0px;
}

.direct-modal input.search__input1 {
    font-size: 14px;
    color: #999999;
    padding-left: 19px;
    border: 0;
}

.direct-modal .users .users-list li.list-group-item {
    border: 0;
    border-bottom: 1px solid #cecece;
    cursor: pointer;
}

.direct-modal .users .users-list li.list-group-item:last-child {
    border-bottom: 0;
}

.direct-modal .users .users-list li.list-group-item img {
    width: 52px;
    height: 52px;
}

.Emoji-Off {
    display: block;
    position: absolute;
    top: 17px;
    left: 8px;
    opacity: 1;
    cursor: pointer;
}
.Emoji-On{
    display: block;
    position: absolute;
    top: 17px;
    left: 8px;
    opacity: 0.2;
    cursor: pointer;
}

.Emoji-Grid {
    background: #FFFFFF;
    box-shadow: 0px 2px 7px 4px rgb(0 0 0 / 10%);
    border-radius: 90px;
    padding: 10px 0 10px 20px;
}

.Emoji {
    width: 60%;
}

.Emoji-Grid span {
    font-size: 25px;
    padding-right: 12px;
    cursor: pointer;
}

.file-upload-image {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 17px;
    right: 8px;
    cursor: pointer;
}

.file-upload-image svg{
    color: #000;
    font-size: 24px;
}

ul.social-profile.group-participant li .img-group {
    display: inline-block;
    background: transparent;
    position: relative;
    vertical-align: bottom;
}

ul.social-profile.group-setting.group-participant li .img-group {
   width: 22px;
   height: 22px;
   margin-right: 10px;
}

ul.social-profile.group-participant li .img-group{
    width: 50px;
    height: 50px;
    vertical-align: middle;
    margin-right: 10px;
}

ul.social-profile.group-participant li .img-group img{
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

ul.social-profile.group-participant li p {
    display: inline-block;
    cursor: pointer;
}

ul.social-profile.group-setting.group-participant li .img-group svg {
    font-size: 16px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
}

.direct-modal .create-group .form-group label {
    font-size: 12px;
    color: #000;
    margin: 0;
    margin-bottom: 5px;
}

/* .direct-modal .create-group .modal-footer {
    padding-right: 0;
} */
.direct-modal .create-group .modal-footer button.btn:last-child {
    margin-right: 0;
}

.direct-modal .create-group .modal-footer.group button.btn:last-child {
    margin-right: 0;
    color:  rgba(189, 192, 196, 1);
}

.direct-modal .create-group .modal-footer.group button.btn {
    background: #E4E6EA;
    border-radius: 7px;
    border: 0;
    color: #000;
}
.group-info-1 .info-list #accordionExample1 .card-body {
    min-height: unset;
}

ul.social-profile.group-participant .more button {
    border: 0;
    width: auto;
    background: transparent;
    padding: 0;
    margin: 0;
}

ul.social-profile.group-participant .more button::after {
    display: none;
}

ul.social-profile.group-participant  span.more .dropdown button img {
    width: auto;
    height: auto;
}

span.more.float-right .show.dropdown .dropdown-menu.show {
    width: 190px;
}

ul.social-profile.group-participant li ul.OptionMenu li {
    margin: 0;
    padding-bottom: 5px;
}

ul.social-profile.group-participant.member {
    min-height: 260px;
    overflow: auto;
    max-height: 260px;
}
ul.social-profile.group-gallery li {
    display: inline;
}

ul.social-profile.group-gallery {
    margin: 7px 5px;
    text-align: center;
    max-height: 250px;
    overflow: auto;
}

ul.social-profile.group-gallery span.img-group {
    width: 75px;
    height: 75px;
}

ul.social-profile.group-gallery span.img-group img {
    width: 75px;
    padding-bottom: 10px;
}

.direct-modal .create-group .modal-footer.group.mute button.btn.btn-primary {
    background: #F11200;
    color: #fff;
}

.direct-modal .create-group .modal-footer.group.mute button.btn.btn-secondary {
    background: transparent;
    color: #F11200;
}

form.create-group.mute p.note {
    font-size: 14px;
    color: #050505;
    padding-right: 50px;
}

form.create-group.mute .custom-control-label::before{
    width: 24px;
    height: 24px;
    border: 2px solid #000;
}

form.create-group.mute .custom-control-label::after{
    width: 24px;
    height: 24px;
}
form.create-group.mute label.custom-control-label {
    font-size: 16px;
    font-weight: 500;
    padding-left: 15px;
    padding-top: 4px;
}

form.create-group.mute .custom-control-input:checked~.custom-control-label:focus {
   outline: 0;
}

form.create-group.mute .custom-control-input:checked~.custom-control-label::before {
    border-color: #000;
    background-color: transparent;
}

form.create-group.mute .custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url(https://katika-images.s3.amazonaws.com/category/Vector1622032346.svg);
}


.direct-modal .create-group.report .form-group label {
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
    height: 40px;
    background: #E4E6EA;
    border-radius: 30px;
    font-weight: 500;
    text-align: center;
    padding: 9px 15px 15px 15px;
}

.direct-modal .create-group.report .custom-radio.custom-control {
    display: inline-block;
    margin: 0 15px 0 0px;
}

.direct-modal .create-group.report .custom-control-label::before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    border:1px solid transparent ;
    border-radius: 30px;
    background: transparent;
    left: 0;
}
.direct-modal .create-group.report .custom-radio .custom-control-input:checked~.custom-control-label::after{
    background: transparent;
}
.direct-modal .create-group.report .custom-control-input:checked~.custom-control-label::before {
    border-color: #F11200;
    color: #000;
    border-width: 2px;
}

.direct-modal h4.heading {
    font-size: 20px;
    color: #000000;
    margin-bottom: 5px;
}

.direct-modal h6.heading {
    font-size: 14px;
    color: #000000;
}
.direct-modal .create-group .sub-heading {
    margin-bottom: 0;
}
.direct-modal .create-group .sub-heading {
    margin-bottom: 15px;
}
.toggle-button button.btn.btn-primary {
    margin: 0;
}
.app .border-start.bg-white .group-info-1 {
    width: 200px;
}
.app .border-start.bg-white .group-info-1 .info-list .card {
    border: 0;
}

.group-participant li {
    margin: 0;
}
.group-participant li span.img-group {
    display: inline-block;
    width: 30px;
    text-align: center;
    color: #000;
}
.group-participant li p {
    width: 80%;
    display: inline-block;
    font-size: 12px;
    color: #000;
}
.head a.close {
    position: fixed;
    right: 20px;
    color: #fff;
    opacity: 1;
}

.stories.carousel .story .item-link span.info .name {
    font-weight: 600;
}

.login-detais h5 {
    font-size: 30px;
    font-weight: 600;
    color: #000;
    text-align: center;
}

.login-detais sup small {
    font-size: 12px;
    font-weight: 600;
}
.login-detais p.muted {
    font-size: 14px;
    text-align: center;
    color: #999999;
    padding: 20px 0;
}

.he .chat-user-info .toggle-button button img {
    width: 100%;
}
section.form-shared.login-page .billing-form-item.mb-0 p.font-weight-medium1 {
    width: 100%;
    text-align: right;
    font-size: 9px;
    font-weight: 400 !important;
    color: #828282;
}
/* ////////////////////////////////////////// */

.mySwiper .swiper-slide.swiper-slide-prev {
    display: inline-block;
    width: 25% !important;
    background: red;
    margin-right: 0 !important;
}

.mySwiper .swiper-slide.swiper-slide-active {
    display: inline-block;
    width: 50% !important;
    background: aqua;
    margin-right: 0 !important;
}

.mySwiper .swiper-slide.swiper-slide-next {
    display: inline-block;
    width: 25% !important;
    background: blue;
    margin-right: 0 !important;
}

.user-chat-search .search-bar {
    display: inline-block;
    width: 80%;
}

.user-chat-search .search-button {
    display: inline-block;
}

.search-button .prev {
    display: inline-block;
}

.search-button .next {
    display: inline-block;
}

.user-chat-search {
    width: 100%;
    display: inline-block;
    padding: 5px 0px 5px 0px;
    position: relative;
}

.user-chat-search .search-bar input.form-control {
    background: #F0F2F5;
    border-radius: 70px;
    padding-left: 45px;
}

.search-button button svg {
    color: #ADADAD;
}

.search-icon span {
    width: 17px;
    height: 17px;
    transform: translate(0%, 0%);
    transition: width 0.4s 0.4s;
    background-image: url(https://katika-images.s3.amazonaws.com/category/eva_search-fill1621927606.png);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 20px;
    z-index: 1;
    top: 16px;
}

.user-chat-search .search-button button {
    background: transparent;
    border: 0;
    padding: 5px;
    font-size: 16px;
    font-weight: 700;
}

.search-button .close1 {
    display: inline-block;
}

.search-button button svg {
    color: #ADADAD;
    font-size: 20px;
}

.reviews-tabs.buyer-story span.info {
    position: absolute;
    left: 0;
    bottom: 10px;
    color: #F12100;;
    font-size: 16px;
    right: 0;
    font-weight: 500;
    white-space: normal;
    width: 100px;
    text-align: left;
    margin: 0 auto;
}

/* .cities.stories-landing h2 {
    font-weight: 600;
    font-size: 14px;
    color: #000;
    width: 30%;
    padding-bottom: 5px;
    border-bottom: 3px solid;
    padding-left: 0;
    margin: 0;
} */

.reviews-tabs.buyer-story  .item-preview img {
    width: 100%;
    border-radius: 8px;
}

/* .alert-content .btn-box button {
    background: transparent;
    color: #999;
    box-shadow: none;
    position: absolute;
    left: -16px;
    top: 0px;
} */

.story-slider {
    background: #000;
    width: 100%;
}

.slick-slide-item {
    width: 100%;
    display: inline-block;
    margin: 0 auto;
    margin-top: 0;
    text-align: center;
    height: 100vh;
    position: relative;
    padding-top: 74%;
    overflow: hidden;
    background: #000;
}
.slick-slide-item video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background: #000;
}

.slick-slide-item img {
    position: relative;
    width: 100%;
    height: 100%;
}
.story-slider.view-story .slick-list {
    height: 100vh;
    width: 370px;
    margin: 0 auto;
}
.story-slider.view-story .slick-track {
    height: 100vh;
}
.story-slider.view-story .slick-slide-item img {
    height: auto;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

/*
essential styles:
these make the slideshow work
*/
#slides{
    position: relative;
    height: 300px;
    padding: 0px;
    margin: 0px;
    list-style-type: none;
  }
  
  .slide{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
  
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
  }
  
  .showing{
    opacity: 1;
    z-index: 2;
  }
  
  
  .controls{
    display: none;
  }
  
  
  /*
  non-essential styles:
  just for appearance; change whatever you want
  */
  
  .slide{
    font-size: 40px;
    padding: 40px;
    box-sizing: border-box;
    background: #333;
    color: #fff;
    
    background-size: cover;
  }
  
  /* http://unrestrictedstock.com/wp-content/uploads/snowy-winter-vignette-bokeh-night-snow-falling-free-stock-photo.jpg */
  .slide:nth-of-type(1){
    background-color: red;
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/123941/snowy-winter-vignette-bokeh-night-snow-falling-free-stock-photo.jpg');
  }
  
  /* http://www.dawghousedesignstudio.com/wp-content/uploads/2010/01/25/free-stock-photos-1.jpg */
  .slide:nth-of-type(2){
    background-color: orange;
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/123941/free-stock-photos-1.jpg');
  }
  
  /* http://www.stockfreeimages.com/static/homepage/waterfall-free-stock-photo-244915.jpg */
  .slide:nth-of-type(3){
    background-color: green;
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/123941/waterfall-free-stock-photo-244915.jpg');
  }
  
  .controls{
    background: #333;
    color: #fff;
    border: none;
    padding: 20px 0px;
    font-size: 20px;
    cursor: pointer;
    border: 2px solid #fff;
    margin: 10px 0px 0px 10px;
    width: 70px;
  }
  
  .controls:hover,
  .controls:focus{
    background: #eee;
    color: #333;
  }
  
  .container{
    position: relative;
  }
  
  .buttons{
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 10;
    font-size: 0px;
  }

  .each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }
  
  .each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
  
/* ////////////////////////////////////////////// */


li.chat-message .ShowHover {
    position: relative;
    background: transparent;
    border: 0;
    box-shadow: unset;
    margin: 0;
    margin-right: 0;
    width: 100%;
    padding: 0;
}

li.chat-message .ShowHover .Emoji-On ,  li.chat-message .ShowHover .Emoji-Off{
    position: absolute;
    left: 47px;
    top: 6px;
    width: 35px;
    height: 30px;
    z-index: 9;
}

.h-emoji {
    position: relative;
    width: 100%;
}

.h-emoji .Emoji-Grid {
    position: absolute;
    left: 40px;
    top: -50px;
}

.reaction-count {
    position: absolute;
    right: 0;
    background: #FFFFFF;
    box-shadow: 0px 2px 7px 4px rgb(0 0 0 / 10%);
    border-radius: 90px;
    padding: 6px 16px;
}

.chat-message-area {
    position: relative;
}

.reaction-count span {
    margin-right: 10px;
}

.reaction-count span:last-child {
    margin-right: 0;
}

.seller-chat main.dashboard-page main.chat-module {
    padding-left: 54px;
    margin-top: 30px;
}

.seller-chat div#page-content-wrapper {
    padding-top: 0;
}

.chat-meta-button button {
    border: 0;
    width: auto;
    background: transparent;
    padding: 0;
    margin: 0;
}

.left-bar-chat{
    position: relative;
}

li.list-group-item:hover{
    background-color: #eeeeee;
}

.chat-meta-button:hover{
    background-color: #a0a0a0;
}
.chat-meta-button {
    background: #fff;
    height: 35px;
     width: 35px;
    border-radius: 50%;
    text-align: center;
    padding-top: 5px;
    color: #000000;
    z-index: 9;
    display: block;
    position: absolute;
    right: 40px;
    top: 12px;
}

.chat-meta-button .dropdown button img {
    height: auto;
    width: auto;
}

.chat-meta-button .dropdown .dropdown-toggle::after {
    display: none;
    
}

.chat-meta-button ul.User-Chat-Button {
    width: 250px;
}

.chat-meta-button ul.User-Chat-Button li {
    padding: 10px 0 7px 10px;
    color: #000000;
    margin: 0 5px;
}

.chat-meta-button ul.User-Chat-Button li .user-control {
    padding-left: 18px;
    font-weight: 500;
    font-size: 14px;
}

.chat-meta-button ul.User-Chat-Button li img {
    width: auto;
    height: auto;
}

.chat-meta-button ul.User-Chat-Button li:hover {
    background: #eeeeee;
    border-radius: 8px;
}

.info-list .accordion-item .accordion__panel{
    margin: 0 5px;
}

ul.social-profile.group-participant li ul.OptionMenu{
    margin: 0 5px;
    width: auto;
}

.info-list .accordion-item .card .card-header{
    margin: 0 5px;
}

ul.list-group.friend-list {
    min-height: 500px;
}

main.BusinessHome .login.section a {
    border-radius: 3px;
    font-size: 14px;
    padding: 10px 10px;
    height: 24px;
    color: #2b273c;
    font-weight: 600;
}

main.BusinessHome .login.section .signup a {
    color: #fff;
    background: transparent;
}

.login.section.biz {
    padding: 0;
    text-align: right;
    height: 100%;
    display: flex;
    align-items: center;
}

.accordion__item .justify-content-between:hover {
    background: #eeeeee;
    border-radius: 0px;
}

.group-info-1 .info-list li:hover {
    background: #eeeeee;
    border-radius: 8px;
}

.caht-option-area .chat-option ul.OptionMenu{
    margin-top: 0;
}
.caht-option-area .chat-option ul.OptionMenu li:hover {
    background: #eeeeee;
    border-radius: 8px;
}


.modal-form.Chat-Gallery .slider-dots.chat-gallery-view {
    height: 100px;
    position: absolute;
    width: 100%;
    bottom: 0;
    background: rgb(40 40 40)
}

.modal-form.Chat-Gallery  .BrainhubCarousel {
    height: 85vh;
    background: transparent;
}

.modal-form.Chat-Gallery .BrainhubCarousel__container .BrainhubCarousel .BrainhubCarousel__trackContainer {
    background: transparent;
}

.modal-form.Chat-Gallery  .BrainhubCarousel__container {
    width: 100%;
    overflow: hidden;
    min-height: 465px;
    height: 100vh;
    background: rgba(28, 28, 28, 0.95);
}

.modal-form.Chat-Gallery .BrainhubCarousel__trackContainer li.BrainhubCarouselItem:hover{
    background: none;
}

.modal-form.Chat-Gallery .BrainhubCarousel__trackContainer .BrainhubCarousel__track li.BrainhubCarouselItem {
    display: inline-block;
    position: relative;
    height: 180px;
    padding-top: 50%;
    overflow: hidden;
    background-color: transparent;
}

.modal-form.Chat-Gallery  .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track li.BrainhubCarouselItem.BrainhubCarouselItem--active img.img-example {
    position: absolute;
    z-index: 9;
    max-width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
}

.modal-form.Chat-Gallery .modal-content{
    border: 0;
}

.modal-form.Chat-Gallery .slider-dots.chat-gallery-view .BrainhubCarousel__dots li {
    margin: 4px;
    width: 50px;
    display: inline-block;
    height: 50px;
}

.modal-form.Chat-Gallery .slider-dots.chat-gallery-view .BrainhubCarousel__dots li:hover{
    background: none;
}

.modal-form.Chat-Gallery .slider-dots.chat-gallery-view .BrainhubCarousel__dots li button.BrainhubCarousel__thumbnail {
    height: 50px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.15);
}

.modal-form.Chat-Gallery .slider-dots.chat-gallery-view ul.BrainhubCarousel__dots img {
    height: 100%;
    width: 100%;
    /* position: absolute; */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    object-fit: contain;
}

.modal-form.Chat-Gallery .modal-dialog.modal-fullscreen-sm-down {
    width: 100%; 
    max-width: none;
    height: 100%;
    margin: 0; 
}

.modal-form.Chat-Gallery .modal-top {
    border-bottom: none;
    margin-bottom: 0;
    padding: 0;
}

.modal-form.Chat-Gallery .chat-header-gallery {
    position: absolute;
    z-index: 9;
    width: 100%;
    display: block;
    text-align: left;
}
.modal-form.Chat-Gallery .chat-header-gallery .gallery-header {
    display: inline-block;
    margin: 40px;
}

.modal-form.Chat-Gallery .chat-header-gallery  .k-logo {
    display: inline-block;
    position: absolute;
    top: 18px;
    margin-left: 20px;
}

.modal-form.Chat-Gallery .chat-header-gallery .gallery-header button.theme-btn.close-btn {
    background: transparent;
    padding: 0;
}

.business .b-logout {
    padding-right: 0;
    border: 2px solid #000;
    width: 141px;
    text-align: center;
    border-radius: 5px;
}

.business .b-logout:hover {
    border-color: #ff6b6b;
}

.business .b-logout a {
    font-size: 15px;
    font-weight: 600;
    display: block;
    transition: all 0.3s;
    position: relative;
    z-index: 1;
    padding: 10px;
    color: #000;
}
/* ////////////////////////////////////////////////////////////////////////// */
.storyList{
    display: flex;
    justify-content: center;
    background: #f9f9f9;
    border-bottom: 1px solid #ddd;
    padding: 15px 20px;
    overflow-x: auto;
}

.storyList .storyItem{
    margin-right: 10px;
}

.storyList .storyItem:last-child{
    padding-right: 15px;
}

.storyList .storyItem .storyColor{
    background: #006266;
    width: 64px;
    height: 64px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.storyList .watched .storyColor{
    background: #aaa;
}

.storyList .storyItem .storyColor img{
    width: 60px;
    height: 60px;
    border-radius: 30px;
    cursor: pointer;
}

.storyList.main .storyItem .storyColor img {
    width: 40px;
    height: 40px;
    border-radius: 30px;
    cursor: pointer;
}

.storyShowBox{
    background: #111;
    color: #fff;
    width: 100%;
    height: 100%;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
}

.storyShowBox .storyShowItem{
    width: 100%;
    height: 100vh;
    /* background-image: url('https://katika-images.s3.amazonaws.com/profile/engage_21618394898.jpg'); */
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
}

.storyShowBox .storyShowItem .storyHeader {
    display: inline-block;
    padding: 5px 0 20px;
    font-size: 0.85rem;
    align-items: center;
    z-index: 20;
}

.storyShowBox .storyShowItem .storyNavigation{
    display: flex;
    flex: 1;
}

.storyShowBox .storyShowItem .storyNavigation .leftNavigation{
    flex: 1;
}

.storyShowBox .storyShowItem .storyNavigation .rightNavigation{
    flex: 2;
}

.storyShowBox .storyHeader img{
    width: 32px;
    height: 32px;
    border-radius: 16px;
}

.storyShowBox .storyHeader a{
    color: #fff;
    text-decoration: none;
}

.storyShowBox .storyHeader .name{
    margin-left: 12px;
    margin-right: 8px;
    font-weight: 600;
}

.storyShowBox .storyHeader .time{
    color: #ccc;
}

.storyShowBox .storyProgressBar{
    height: 10px;
    margin-top: 10px;
    display: flex;
    padding: 0px;
}

.storyShowBox .storyProgressBar .progressBarOut{
    flex: 1;
    height: 3px;
    background: #aaa;
    border-radius: 5px;
    margin: 0 3px;
}

.storyShowBox .storyProgressBar .progressBarOut .progressBarIn{
    background: #fff;
    width: 0;
    height: 3px;
}
.story-arrow {
    position: relative;
    width: 410px;
    height: 87vh;
    margin: 0 auto;
}
.ShowData {
    position: absolute;
    padding-top: 0%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.15);
    width: 325px;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
.leftNavigation, .rightNavigation {
    position: absolute;
    left: 0;
    top: 50%;
}
.rightNavigation {
    right: 0;
    left: auto;
}
.ShowData img.ImgShow, .ShowData video.VideoShow {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: -77px;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    object-fit: contain;
}

.VolumeControl {
    display: inline-block;
}

.VolumeControl .mute , .VolumeControl .unmute{
    display: inline-block;
}

.leftMenu {
    width: 30%;
    display: inline-block;
    z-index: 9999;
    background: white;
    height: 100%;
    padding: 0;
}

.App .storyShowBox .storyShowItem{
    width: 70%;
    display: inline-block;
    right: 0;
    position: absolute;
}


.storyList .storyItemLeft .storyColor1 img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    border: 2px solid #006266;
    border-radius: 40px;
}

.storyList .storyItemLeft .storyColor1 {
    width: 64px;
    height: 64px;
    display: inline-block;
    justify-content: center;
    align-items: center;
}

.storyDescription {
    display: inline-block;
    color: #000;
    vertical-align: middle;
    padding-left: 15px;
}
.storyItemLeft:hover {
    background: #eeeeee;
    border-radius: 8px;
}
.storyItemLeft {
    padding: 10px 10px;
    z-index: 20;
}

.storyList .storyItemLeft.storyItem {
    margin-right: 0;
    cursor: pointer;
}
.video-control {
    width: 325px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
}
.playVolume {
    position: absolute;
    top: 25px;
    right: 10px;
}
.playVolume .StopNavigation {
    display: inline-block;
    margin: 0 15px 0 0px;
}

.storyList.main{
    padding: 0;
    overflow: unset;
    border-bottom: 0;
}
.storyList.main .storyItem .story-preview {
    width: 100%;
    position: relative;
    height: 300px;
    padding-top: 50%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 7px;
}
.storyList.main .storyItem .story-preview img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    object-fit: cover;
}
.storyList.main .storyItem {
    position: relative;
    width: 205px;
    margin: 0 5px;
}
.storyList.main .storyItem .storyColor {
    background: #fff;
    width: 44px;
    height: 44px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    left: 15px;
    top: 15px;
}
.storyList.main .storyItem h6.story-user-name {
    position: absolute;
    bottom: 20px;
    width: 74px;
    white-space: normal;
    line-break: anywhere;
    left: 20px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    vertical-align: top;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.storyDescription h6 {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}
.storyDescription p {
    font-size: 12px;
    color: #0073BB;
}


.chat-room .friend-list .list-group-item.active img{
    background: #fff;
}

.chat-room .friend-list .list-group-item img{
    background: #eeeeee;
    object-fit: scale-down;
}

.chat-user-info .user-pic img.user-avtar {
    height: 32px;
    border-radius: 50%;
    background: #eeeeee;
    object-fit: scale-down;
}
.collaps-sidebar .fix-sidebar.fix-sidebar-top {
    top: 25px;
}

main.dashboard-page.BuyerProfile.BuyerReviews.BookMarks.chat-page-style .container-fluid {
    padding-left: 0;
    padding-right: 0;
}
main.dashboard-page.BuyerProfile.BuyerReviews.BookMarks.chat-page-style .BuyerContent {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 0px;
}

.logo.listing a img {
    /*max-width: 225px;*/
    min-width: 100%;
}
.VolumeButton {
    position: absolute;
    bottom: 0;
    right: 20px;
}
.story-slider.view-story {
    height: 100vh;
}
.story-slider.view-story .slick-slide-item .storyHeader.detasil {
    width: 120px;
    height: auto;
    z-index: 9;
    position: absolute;
    padding-top: 13%;
    overflow: hidden;
    left: 10px;
    top: 10px;
    display: inline-block;
    border-radius: 0;
}
.story-slider.view-story .slick-slide-item .storyHeader.detasil img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    object-fit: contain;
    min-height: auto;
}
.story-slider.view-story .slick-arrow {
    right: 15px;
}
.story-slider.view-story .slick-arrow.slick-prev {
    left: 15px;
}
.cities.stories-landing .App {
    width: 100%;
    background: #f9f9f9;
    padding: 50px 0;
}

.storyItemLeft.storyItem.active {
    background: #eeeeee;
    border-radius: 8px;
}

.leftMenu.storyList .k-story-logo {
    display: inline-block;
}

.leftMenu.storyList .close-story {
    display: inline-block;
    background: #a7a7a7;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    padding-top: 7px;
    font-size: 20px;
    font-weight: bold;
    margin-right: 20px;
    cursor: pointer;
}

.leftMenu.storyList .TopHeader {
    display: inline-block;
    width: 100%;
    padding: 15px 25px;
    border-bottom: 2px solid #d2cfcf;
    position: relative;
}

.leftMenu.storyList .stories-list {
    margin: 10px;
}

.leftMenu.storyList .story-title {
    padding: 15px 0 0 25px;
    color: #000;
    font-size: 18px;
}


.story-reaction {
    position: absolute;
    bottom: 100px;
    width: 60%;
    height: auto;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.story-reaction .message {
    display: flex;
}

.message .review-stroy {
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 30px;
    background: transparent;
    color: #fff;
}

.submit-story-reaction{
    background: transparent;
    border: 0;
    padding-left: 10px;
}

.message .review-stroy:focus {
    background: transparent;
    color: #fff;
}

input.form-control.review-stroy:focus + .submit-story-reaction img.direct {
    height: 20px;
    width: 20px;
}

.submit-story-reaction {
    display: block;
}

input.form-control.review-stroy:focus + .submit-story-reaction {
    background: transparent;
    position: absolute; 
    right: 20px;
    top: 6px;
    display: block !important;
}

.story-footer {
    position: absolute;
    bottom: 0;
    width: 47%;
    height: auto;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.story-footer .like, .story-footer .comment, .story-footer .share {
    display: inline-block;
    width: 33%;
    text-align: center;
}


.stories-landing-user h2 {
    color: #f11200;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 2px solid;
    width: 30%;
    padding-left: 0;
    padding-bottom: 10px;
    margin-top: 30px;
}

/* ////////////////////////////////////////////// */

.story-top {
    position: absolute;
    margin: 15px;
    width: 98%;
    z-index: 999;
}
.storyHeader.detasil span.name {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
}

.TopHeaderLeft .k-story-logo {
    display: inline-block;
    margin-left: 20px;
}
.TopHeaderLeft .close-story {
    display: inline-block;
}

.topHeaderRight {
    right: 15px;
    position: absolute;
    top: 0;
    color: #fff;
}

.navigate-arrow-slick {
    position: absolute;
    top: 46%;
    margin: 10px;
    width: 98%;
}

.navigate-arrow-slick button {
    background: transparent;
    border: 0;
}

.navigate-arrow-slick .right {
    position: absolute;
    right: 0;
}

.user-body .left {
    display: inline-block;
}

.user-body .right {
    display: inline-block;
    float: right;
    margin-right: 20px;
}
.right button.follow {
    width: 100px;
    height: 32px;
    border: 1px solid #F11200;
    box-sizing: border-box;
    border-radius: 3px;
    background: transparent;
    font-size: 16px;
    color: #F11200;
    font-weight: bold;
}

.gallery-story .user-img-details {
    border: 0;
    width: 34px;
    height: 34px;
    margin-top: 0;
}

.gallery-story .user-img-details .avatar__img {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    /* border: 4px solid #C4C4C4; */
    box-shadow: 0 0 40px rgb(82 85 90 / 10%);
}

.user-body {
    position: relative;
}
.user-body .left {
    display: inline-block;
    position: absolute;
    top: 5px;
}

.comment.storyComment span.comment-date {
    font-size: 12px;
    font-weight: 500;
    color: #999999;
    padding-right: 14px;
}

.user-body .left {
    font-size: 12px;
    font-weight: 500;
}

.user-body .left .uppercase {
    text-transform: uppercase;
}

.details-info {
    /* margin-top: 30px; */
    color: #000;
    /* padding-left: 15px; */
    /* padding-right: 15px; */
}

.story-data {
    position: relative;
    margin-top: 30px;
    margin-right: 20px;
    border-bottom: 1px solid #EEEEEF;
    padding-bottom: 20px;
}

.story-data .description {
    font-weight: 500;
    font-size: 12px;
}

.story-data .title{
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    margin-top: 15px;
}

.count-data {
    margin-top: 30px;
}


.count-data .count-story {
    display: inline-block;
}

.share-story {
    display: inline-block;
    float: right;
}

ul.comments-list.listing-gallery-p {
    font-size: 12px;
    font-weight: 500;
}

.story .like, .story .comment {
    display: inline-flex;
    text-align: center;
    margin-bottom: 0;
}

.story .like .imga , .story .comment .imga {
    display: inline-block;
    width: 28px;
    height: 28px;
    background: #F0F0F1;
    border-radius: 50%;
    padding-top: 1px;
    text-align: center;
}

.story .comment .imga img{
    width: 12px;
}

.story .count {
    margin-left: 4px;
    font-size: 12px;
    font-weight: 500;
    padding-top: 3px;
    margin-right: 15px;
}

.ShareButton {
    display: inline-block;
    padding-left: 7px;
}

.share-text {
    display: inline-block;
}

.manual-link .form-group.share-link {
    position: relative;
    border: 1px solid #eeeeee;
}

.manual-link .form-group.share-link input.form-control {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 10px;
    width: 80%;
    border: 0;
}
.manual-link .form-group.share-link span {
    border-right: 0;
    right: 10px;
    left: auto;
    top: 8px;
    width: auto;
}
.manual-link {
    border-bottom: 1px solid #EEEEEF;
    padding-bottom: 15px;
}
.comment-section {
    overflow: auto;
    border-bottom: 1px solid #EEEEEF;
    min-height: 390px;
    max-height: 390px;
    display: flex;
    flex-direction: column-reverse;
}
.manual-link .copy-link {
    display: inline-block;
    color: #F11200;
    padding-left: 7px;
}

.comment.storyComment .comment-body .comment__author {
    font-size: 14px;
    color: #000;
    font-weight: 500;
}
.comment.storyComment .comment-body .comment-content {
    font-size: 12px;
    margin: 0;
    width: 100%;
    white-space: normal;
}

.comment.storyComment .comment-body .msg-body {
    width: auto;
    background: #e7f3ff;
    margin: 0px ;
    padding: 5px 10px;
    border-radius: 10px;
    color: #000;
}

.reple .comment.storyComment.reply-coment .msg-body {
    background: #f0f2f5;
}

.comment.storyComment .comment-body {
    margin-left: 10px;
    padding-top: 0;
    width: 100%;
}
.comment.storyComment .comment-body .meta-data {
    position: relative;
    width: 100%;
    display: inline-block;
}
.comment.storyComment.reply-coment .comment .comment-body {
    margin-left: 16px;
    padding-top: 0;
}

.comment.storyComment img.avatar__img {
    width: 40px;
    height: 40px;
    background: #eeeeee;
    object-fit: cover;
}

.heart-count {
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    color: #999999;
}
.comment-time {
    display: inline-block;
    width: 100%;
    text-align: right;
    padding: 0 30px;
    font-size: 12px;
    margin: 0;
}
.accordion-item .card .reply-comment-shown.d-flex.align-items-center {
    color: #999999;
    font-size: 12px;
    font-weight: 500;
    padding: 0;
    padding-left: 15px;
    margin-bottom: 15px;
    margin-top: 5px;
}

.comment.storyComment {
    margin: 0 15px 5px;
}

.accordion-item .card .reply-comment-shown.d-flex.align-items-center i {
    background: transparent;
    background-color: transparent;
    color: #999999;
    line-height: 27px;
    box-shadow: unset;
}

.post-meta .comment-post-area .storycoment {
    border-radius: 50px;
    width: 100%;
    outline: none;
    border: none;
}

.storycoment {
    background: #F1F1F2;
    border-radius: 7px;
    width: 82%;
    color: #000;
    font-size: 12px;
    font-weight: 400;
    min-height: 35px;
}
p.pl-2.my-0.storycoment.textarea span, p.pl-2.my-0.storycoment.textarea strong {
    font-family: inherit !important;
    background: transparent !important;
}
p.pl-2.my-0.storycoment.textarea {
    outline: none;
}

p.pl-3.my-0.storycoment.textarea {
    outline: none;
    width: 100%;
}

.storycoment.form-control:focus {
    color: #000;
    background-color: #F1F1F2;
}

.form-group.basic-textarea.reaction-2 .Emoji-Off {
    left: auto;
    right: 80px;
    display: block;
    top: 8px;
}

.form-group.basic-textarea.reaction-2 .Emoji-On {
    left: auto;
    right: 80px;
    display: block;
    top: 8px;
    opacity: 0.2;
}

.Emoji.story-comment {
    width: 80%;
    position: absolute;
    top: -55px;
}


.add-comment .reaction-2 button.Emoji-Submit-comment{
    background: transparent;
    border: 0;
    color: #999999;
    font-size: 11px;
    font-weight: 500;
    background: transparent;
    border: 0;
    margin: 0;
}

.comment.storyComment .comment-body .reply-coment-story {
    font-weight: 500 !important;
    font-size: 12px;
    border: 0;
    background: transparent;
    color: #999999 !important;
}
.stroy-media .col-lg-8.col-md-8, .stroy-media .col-lg-4.col-md-4 {
    padding: 0;
}

.warning-list .warning {
    display: block;
    text-align: center;
}

.warning-list .warning .la.warning-icon {
    color: #f9b851;
    font-size: 60px;
    display: inline-block;
}
.warning-list h4.modal-title {
    color: #000;
    font-size: 17px;
}
.warning-list .btn-box button.border-0.button-success.mr-1 {
    width: 100px;
    height: 40px;
    color: #fff;
    border-radius: 8px;
}

section.dashboard-area.account-page.seller-stories .stories-landing-user h2 {
    width: 45%;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 2px;
    text-align: center;
    margin-left: 0;
} 

.storyShowItem .share-social {
    width: 500px;
    margin: 0 auto;
    background: #fff;
    padding: 30px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    border-radius: 8px;
    text-align: center;
    z-index: 1;
}

.storyShowItem .share-social .shareheading {
    font-size: 18px;
    color: #000000;
    padding-bottom: 15px;
}

.storyShowItem .share-social .social-share button {
    border: 3px solid #F11200;
    box-sizing: border-box;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: transparent;
}

.storyShowItem .share-social .social-share button svg {
    font-size: 36px;
    color: #F11200;
}

.storyShowItem .share-social .close-share {
    display: block;
    position: absolute;
    right: 20px;
    top: 15px;
    background: #E5E5E5;
    width: 23px;
    border-radius: 50px;
    cursor: pointer;
}

.storyShowItem .share-social .social-share a {
    border: 3px solid #F11200;
    box-sizing: border-box;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: transparent;
    display: block;
    line-height: 4;
}
.storyShowItem .share-social .social-share a svg {
    font-size: 36px;
    color: #F11200;
}

.storyDescription span.time-story {
    color: #000;
    margin-left: 13px;
}

.storyDescription span.time-story-dot {
    /* content: '.'; */
    position: absolute;
    border: 0;
    color: 000;
    height: 4px;
    width: 4px;
    background-color: #000;
    border-radius: 50%;
    margin-left: 4px;
    margin-right: 3px;
    margin-top: 9px;
}



.details-info.story-details-info {
    margin-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
}

.UserPicture.businessLogo {
    position: relative;
    width: 240px;
    height: 200px;
    margin: 0 auto;
    cursor: pointer;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.15);
}

main.dashboard-page .author-bio .UserPicture.businessLogo img {
    border-radius: 3px;
    border: 0;
    box-shadow: none;
    background: #eeeeee;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    object-fit: cover;
    width: 100% !important;
}

ul.list-group.friend-list .left-sidebar-chat {
    position: relative;
}

ul.list-group.friend-list span.user-onlie-status {
    position: absolute;
    right: 8px;
    bottom: -2px;
    width: 12px;
    height: 12px;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 6px;
}

span.user-onlie-status.red{
    background: red;
}

span.user-onlie-status.green{
    background: green;
}
span.user-onlie-status.yellow{
    background: yellow;
}

section.dashboard-area .b-fb-share {
    display: inline-block;
}

.manual-link .form-group.share-link.group-link {
    border: 1px solid #007AFF;
    margin-bottom: 0;
}

.manual-link .form-group.share-link.group-link input.form-control {
    padding-left: 8%;
    width: 88%;
    background: transparent;
    font-size: 14px;
    font-weight: 400;
    color: #000;
}

.direct-modal .manual-link.group-link {
    border: 0;
    padding: 0;
}

.manual-link .form-group.share-link.group-link span {
    right: 29px;
    color: #000;
    font-size: 24px;
    top: 7px;
    cursor: pointer;
}

section.detail-gallery .overlay-listing {
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-size: cover;
    right: 0;
    position: absolute;
    background: linear-gradient( 180deg,rgba(0,0,0,.0001) 31.42%,#000);
    content: "";
    pointer-events: none;
}

.navbar-chat-help {
    padding-top: 22px;
    color: #000;
}

.navbar-chat-help h6.help {
    font-weight: 600;
    font-size: 14px;
    padding-top: 17px;
}
.navbar-chat-help .logo {
    padding-top: 10px;
}

.help-search {
    position: relative;
}

.help-search .search-icon {
    position: absolute;
    left: 12px;
    top: 8px;
}

.help-search .h-search::placeholder {
    color: #000;
    font-size: 14px;
    font-weight: 400;
}

.help-search .h-search {
    background: #E5E5E5;
    border-radius: 3px;
    padding-left: 40px;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
    height: 41px;
}

.help-tabs-top .react-tabs__tab-list {
    text-align: left;
    padding-left: 9px;
    border: 0;
    font-size: 14px;
    color: #000000;
}

.help-tabs-top .react-tabs__tab-list li.react-tabs__tab.react-tabs__tab--selected{
    font-weight: 600;
}


.right-bar ul.react-tabs__tab-list {
    font-size: 16px;
    width: 250px;
}

.tabs-content.sending {
    padding-top: 20px;
}

.right-bar ul.react-tabs__tab-list li.react-tabs__tab.react-tabs__tab--selected{
    font-weight: 600;
    color: #F11200 !important;
    border: 0 !important;
}

.right-sending-1 .select-right {
    font-weight: 600;
    font-size: 28px;
    color: #000000;
}


.rules-area .accordion-item .card .btn i.rules-caret {
    width: auto;
    height: auto;
    border: 0;
    background-color: transparent;
    color: #000;
    box-shadow: unset;
    line-height: 0;
    right: 12px;
}

.rules-area .accordion-item .card .btn {
    width: 100%;
    font-weight: 600;
    font-size: 20px;
    color: #606770;    
    text-decoration: none;
    padding: 14px 0;
}

.rules-area  .accordion-item .card:not(:last-of-type), .accordion-item .card:not(:first-of-type) {
   border-radius: 0;
}

.rules-area .accordion-item .card:not(:first-of-type) {
    border-bottom: 1px solid rgba(128, 137, 150, 0.2);
}

.rules-area p.rule-detail {
    font-size: 14px;
    padding: 10px 0;
    text-align: justify;
    color: #000;
}

.rules-area .sub-heading-rule {
    font-weight: 600;
    font-size: 22px;
    color: #000000;
    margin-bottom: 15px;
}

section.dashboard-area.account-page.seller-stories .reviews h4 {
    font-weight: 600;
    font-size: 20px;
    color: #2B273C;
}

main.signup-page.BizSignUp .input-box1 label {
    color: #000;
    font-size: 15px;
}

section.rdu .info-message {
    text-align: center;
}

section.rdu .info-message em {
    color: rgba(128, 137, 150, 0.7);

}

section.container.rdu .dzu-dropzone{
    color: rgba(128, 137, 150, 0.7);
    border: 2px dashed rgba(128, 137, 150, 0.3);
    text-align: center;
    transition: box-shadow 0.3s, border-color 0.3s;
    height: 170px;
    overflow: hidden;
}

section.container.rdu button.dzu-submitButton {
    background: #f11200;
    border-color: #f11200;
    height: 40px;
    font-weight: 400;
    border: 1px solid transparent;
    font-size: 16px;
    font-family: 'Poppins';
    float: right;
}

section.container.rdu .dzu-submitButtonContainer {
    display: flow-root;
}

section.container.rdu img.dzu-previewImage {
    display: block;
    width: auto;
    max-width: 200px;
    max-height: 100px;
}

.Img-p .sign-up-image {
    text-align: center;
    margin-top: 20px;
}

.processBtn .btn-p {
    display: flex;
}
.processBtn .btn-p a {
    width: 426px;
    height: 64px;
    line-height: 64px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background: #F11200;
    border-radius: 6px;
    margin-top: 40px;
}

.processBtn .btn-p a.buyer{
    background: #F11200;
}

.processBtn .btn-p a:hover{
    color: #fff;
    background: #B30C00;
}

.UserPicture .hover-text {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
}

.dropdrown-login.dropdown button#dropdown-basic {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    padding-top: 14px;
    border: 0;
    text-decoration: none;
}

.dropdrown-login.dropdown .dropdown-menu.show {
    transform: translate(-100px, 47px) !important;
}

.dropdrown-login.show.dropdown a {
    font-weight: normal;
    font-size: 12px;
    color: #000000;
}

.dropdrown-login .dropdown-menu{
    padding: 0;
}

.dropdrown-login.show.dropdown a:hover {
    background: #EBECF0;
    border-radius: 2px 2px 0px 0px;
    color: #000;
}

.dropdrown-login.dropdown .dropdown-toggle::after {
  display: none;
}


section.dashboard-area .button-profile {
    display: inline-block;
    background: #A6CEE3;
    border-radius: 3px;
    margin-right: 10px;
    margin-top: 10px;
}


.button-profile .remove-btn-bg {
    background: #0073BB;
    border-radius: 0px 0px 3px 3px;
    margin-top: 5px;
    padding: 0 12px;
}


section.dashboard-area .button-profile.button-open {
    background: #D1FFEC;
}


.button-profile.button-open .remove-btn-bg {
    background: #326D62;
}

section.dashboard-area .button-profile.button-copy {
    background: #FFF2D1;
}


.button-profile.button-copy .remove-btn-bg {
    background: #FF6435;
}

section.dashboard-area .button-profile span.dashboard-icon{
    color: #fff;
    margin: 0;
    padding: 8px;
}

.d-url {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    display: inline-flex;
    margin-top: 20px;
}

.online-business-images {
    text-align: center;
    margin-top: 37%;
}

span.dashboard-icon.right {
    float: right;
    display: inline-block;
}

main.login-page .btn-box {
    margin-top: 20px;
}

main.login-page .btn-box button.theme-btn.btn-login.border-0 {
    background: #1565D8;
    border-radius: 6px;
}
span.showPassword {
    position: absolute;
    right: 15px;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
}
main.login-page .display-inline-block {
    text-align: center;
}
label.label-login {
    font-weight: 500;
    font-size: 16px;
    color: #696F79;
    margin-bottom: 10px;
}

.processSignUp p.font-size-16.font-weight-medium h2 {
    font-weight: 600;
    font-size: 36px;
    color: #F11200;
    margin-bottom: 15px;
}

.processSignUp .h5 {
    font-weight: 600;
    font-size: 20px;
    color: #000000;
}

.processSignUp .h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
}

.processSignUp .content-area.personal ul li svg{
    color: #4CAF50;
}

.processSignUp .content-area ul li svg {
    font-size: 20px;
    color: #1565D8;
    margin-top: -3px;
}

.processSignUp .content-area ul li {
    font-size: 14px;
    color: #000000;
    padding-bottom: 8px;
}
main.signup-page .display-inline-block {
    text-align: center;
}
.content-area-signup {
    font-size: 16px;
    color: #000000;
}

.content-area-signup ul li svg {
    font-size: 20px;
    color: #F11200;
}

.contact-form-action .date-of-birth .label-text {
    color: #000;
}

.terms-checkbox .checkbox {
    display: inline-flex;
    width: 24px;
    height: 24px;
    background: #C4C4C4;
    border-radius: 4px;
}

.terms-checkbox .term-url a {
    color: #1565D8;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline !important;
}

.terms-checkbox input[type="checkbox"] {
    width: 18px;
    height: 18px;
    margin: 3px !important;
}

.terms-checkbox .term-url{
    font-size: 16px;
}

.terms-checkbox {
    display: inline-flex;
}

.form-shared.login-page .input-box input.form-control, .form-shared.login-page .contact-form-action .terms-checkbox p.term-url {
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    padding-left: 5px;
    width: 100%;
}

main.signup-page .contact-form-action .form-control::-webkit-input-placeholder { /* Edge */
    color: #000;
}
  
main.signup-page .contact-form-action .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #000;
}
  
main.signup-page  .contact-form-action .form-control::placeholder {
    color: #000;
}

main.signup-page.BizSignUp .contact-form-action select.form-control {
    color: #000;
    font-size: 13px;
    resize: none;
    font-weight: 500;
    transition: all .3s;
}
  



main.signup-page .form-group.login-btn span button.theme-btn,
main.login-page .form-group.login-btn span button.theme-btn,
main.signup-page .form-group.login-btn button,
main.login-page .form-group.login-btn button,
main.signup-page .form-group.login-btn span,
main.login-page .form-group.login-btn span {
    width: 100% !important;
    /* background: #fff; */
    height: 36px;
    line-height: 36px;
    box-shadow: none !important;
    border-radius: 6px;
    border: 0 !important;
    justify-content: center;
    border-radius: 50px !important;
    font-weight: normal;
    opacity: 1 !important;
    color: #fff !important;
}
.billing-content .login-btn .theme-btn svg {
    font-size: 22px;
}
.input-box.date-of-birth .css-1wa3eu0-placeholder {
    color: #000!important;
    font-size: 12px;
}

.billing-form-item.mb-0 a.color-text {
    color: #F11200 !important;
    font-weight: 500;
    font-size: 12px;
}

.sidebar-widget.single-h .online-business-images {
    margin-top: 15%;
}

.top-right.user-login-info .login.section .dropdrown-list button#dropdown-basic {
    border-radius: 3px;
    font-size: 14px;
    padding: 0;
    height: 24px;
    color: #2b273c;
    font-weight: 600;
    text-decoration: none;
}

.top-right.user-login-info .login.section .dropdrown-list button#dropdown-basic:after {
    display: none;
}

.list-page-button.dropdown-menu.show {
    padding: 0;
}

.top-right.user-login-info .login.section .dropdrown-list a:hover {
    background: #EBECF0;
    border-radius: 2px 2px 0px 0px;
    color: #000;
}

.dropdrown-list.dropdown .dropdown-menu.show {
    transform: translate(-50px, 47px) !important;
}

.top-right.user-login-info .login.section .dropdrown-list a {
    font-size: 14px;
    color: #2b273c;
    display: block;
    transition: all 0.3s;
    margin: 0;
    /* padding: 0; */
    height: 40px;
}

h2.modal-title.mt-2.mb-1 {
    color: #000;
    font-size: 23px;
}

.modal-body .billing-content .display-inline-block {
    text-align: center;
}

main.login-page.b-login.b .contact-form-action {
    width: 425px;
}

main.login-page.b-login.b section.form-shared.login-page .billing-form-item.mb-0 p{
    width: 100%;
    margin-top: 10px;
    color: 'red';
}

main.login-page.b-login.b section.form-shared.login-page .input-box{
    width: auto;
}

main.login-page.b-login.b section.form-shared.login-page .input-box.login input.form-control {
    width: 100%;
}

p.text-term-condition-login{
    color: #999999 !important;
    font-size: 12px;
    font-weight: 400;
}
p.text-term-condition-login a{
    color: #999999 !important;
}

.sidebar.section-bg.details-sidebar .social ul li a{
    color: #326D62;
}
img.dashboard-alert-satrted {
    margin-top: -2px;
}

strong.font-weight-bold.bactive {
    color: #5CD3B7;
}

strong.font-weight-bold.bpending {
    color: #F11200;
}

.dm-icon.dm .dm-icon-img img {
    background: none;
}

.author-inner-bio.pb-0.dm {
    padding-top: 20px;
    padding-left: 15px;
}

.cities.stories-landing .Toastify__toast-container {
    z-index: 99999;
}
.storyHeader.detasil.name {
    width: 60% !important;
    text-align: left;
}

.mid-border {
    height: 450px;
    width: 1px !important;
    border-left: 1px solid #EEEEEF;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 80px;
}

.billing-content.BSignupForm .form-group small {
    font-weight: 700;
    font-size: 12px;
    color: #F11200;
}

.css-2b097c-container {
    z-index: 99999;
}

.signup-picture .content-area-signup li svg {
    margin-right: 10px;
}

.signup-picture .content-area-signup li {
    padding-bottom: 5px;
    font-size: 14px;
    display: inline-flex;
}

.chat-user-info .toggle-button button {
    border: 0;
}

.chat-user-info span.info-icon svg {
    font-size: 23px;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    /* top: 6px; */
    bottom: 0;
    margin: 0 auto;
}

.chat-user-info span.info-icon {
    width: 23px;
    height: 23px;
    background: #E7ECF1;
    text-align: center;
    position: relative;
}

h3.categories-head {
    font-size: 24px;
    font-weight: 600;
    color: #000;
    margin: 30px 0 30px 0;
}

.cities.recent.BuyerPhotos.categories .all-categories {
    width: 100%;
}

ul.comparison-card-list {
    columns: 4;
    -webkit-columns: 4;
    -moz-columns: 4;
    /* display: grid;
    grid-template-rows: repeat(90, min-content);
    grid-auto-flow: column; */
}

ul.comparison-card-list .parent a{
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #000000;
    padding-bottom: 4px;
}

ul.comparison-card-list .child a{
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    padding-bottom: 4px;
}

main.dashboard-page.BuyerProfile.BuyerReviews .BuyerContent .reviews-tabs.buyer-story .story.custom-story {
    height: 225px;
}

.input-box.date-of-birth .form-group .css-1uccc91-singleValue {
    color: #333f57;
}

.user-area #user-picture .user-information .user-name h2.name-link a {
    float: left;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1877f2;
}

.nav-listing-search .top-right.user-login-info .login.section .dropdrown-list a {
    font-weight: normal;
}

.chat-message-area a.chatMedia {
    margin-left: 80px;
    width: 300px;
    height: 168px;
}

.chat-message-area a.chatMedia img.img-thumbnail {
    width: auto !important;
    height: 160px !important;
}

main.home-1 .cities h2 {
    font-size: 21px;
    font-weight: 700;
}

.user-name.follow-button button.btn.btn-primary {
    background: transparent;
    color: #000;
    border: 1px solid;
    font-weight: 600;
    font-size: 14px;
    width: 88px;
    margin-bottom: 20px;
}

.link-preview .card {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    width: 302px;
    margin-left: 80px;
}

.chat-module .full-width .link-preview .card img.card-img-top {
    height: auto;
    width: 100%;
    display: block;
    max-height: 200px;
    object-fit: scale-down;
}

.link-preview .card .card-body .card-title.h5 {
    font-size: 16px;
    font-weight: 700;
    color: #393939;
    margin-top: 10px;
}

.link-preview .card .card-body p.card-text {
    font-size: 14px;
    font-weight: 400;
    color: #C1C1C1;
}

.chat-module .full-width .link-preview .card .card-body {
    padding-left: 10px !important;
}

main.BusinessHome.FaqPages.Labs .CommonBreadcrumb h1 {
    color: #fff;
}

main.BusinessHome.FaqPages.Labs .CommonBreadcrumb {
    background-image: url("https://katika-images.s3.us-east-2.amazonaws.com/category/Katika%20Labs1633938256.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

main.BusinessHome.FaqPages.Partners .CommonBreadcrumb h1 {
    color: #fff;
}

main.BusinessHome.FaqPages.Partners .CommonBreadcrumb {
    background-image: url("https://katika-images.s3.us-east-2.amazonaws.com/category/Partners1633938680.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

main.BusinessHome.FaqPages.Community .CommonBreadcrumb {
    background-image: url("https://katika-images.s3.us-east-2.amazonaws.com/category/1655899441.commnity.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
}



main.BusinessHome.FaqPages.FAQ .CommonBreadcrumb h1 {
    color: #fff;
}

main.BusinessHome.FaqPages.FAQ .CommonBreadcrumb {
    background-image: url("https://katika-images.s3.us-east-2.amazonaws.com/category/FAQS1633938240.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}


main.BusinessHome.FaqPages.PRESS .CommonBreadcrumb h1 {
    color: #fff;
}

main.BusinessHome.FaqPages.PRESS .CommonBreadcrumb {
    background-image: url("https://katika-images.s3.us-east-2.amazonaws.com/category/Press1633938981.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}


/* ////////////////////////////////////////////////// */

.icon-box-layout .info-icon img.card__img {
    width: auto;
    height: 424px;
}

.card-item.col-last .user_review img.card__img {
    width: auto;
}

ul.help-map-address li {
    display: flex;
    margin-bottom: 30px;
    padding-left: 10;
}

ul.help-map-address li span.la {
    padding-right: 20px;
}

.help-address-box{
    margin-left: 30px;
    font-size: 16px;
    color: #0F001A;
}

.copyright-left-side.help ul.list-items.SocialIcons {
    display: inline-flex;
    margin-bottom: 10px;
}

.copyright-left-side.help ul.list-items.SocialIcons li {
    padding-right: 15px;
}

.copyright-left-side.help ul.list-items.SocialIcons li a {
    background: transparent;
}
.copyright-left-side.help ul.list-items.SocialIcons li a svg {
    color: #0F001A;
    font-size: 18px;
}

form.form-help .input-box input.form-control {
    height: 50px;
    color: #212529;
    font-size: 16px;
}

form.form-help .input-box textarea.form-control {
    color: #212529;
    font-size: 16px;
}

form.form-help .form-group {
    margin-bottom: 30px;
}

main.BusinessHome.FaqPages.GetHelp .heading {
    width: 155px;
    margin: 0 auto;
    text-align: center;
}

main.BusinessHome.FaqPages.GetHelp .widget-title {
    font-size: 24px;
    color: #F11200;
    font-weight: 700;
    padding-bottom: 5px;
    border-bottom: 3px solid #F11200;
}

main.BusinessHome.FaqPages.GetHelp h2.send-message {
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 20px;
    color: #0F001A;
    padding-left: 5px;
}

form.form-help button.theme-btn.border-0.help-submit {
    background-color: #F11200;
    width: 100%;
}

form.form-help .help_reason{
    height: 50px;
}

.contact-help {
    background: #FFFFFF;
    box-shadow: 2px 10px 28px rgb(75 0 129 / 12%);
    border-radius: 10px;
    margin: 50px 0;
    padding: 0 30px;
}

.deactivate button {
    background: #C4C4C4;
    float: right;
    font-size: 12px;
    font-weight: 500;
    color: #666666;
    border: 0;
}
.change-password .password label {
    font-weight: 400;
}

.info-box-overlay {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #cccccc;
    border-radius: 50%;
    text-align: center;
    line-height: 19px;
    margin: 0 10px;
}

.info-box-overlay svg {
    font-size: 12px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

main.BusinessHome.FaqPages.GetHelp .CommonBreadcrumb {
    background-image: url("https://katika-images.s3.us-east-2.amazonaws.com/category/AdobeStock_2453450721638794505.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
}

main.BusinessHome.Plans.account-page .CommonBreadcrumb {
    background-image: url("https://katika-images.s3.us-east-2.amazonaws.com/category/Mask%20group1671013554.png");
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 20px;
}
main.BusinessHome.FaqPages.GetHelp.BizHome.dashboard-page.Plans.account-page {
    background: #fff;
}
.apps-logo {
    display: inline-grid;
    grid-template-columns: 45% 50%;
    grid-column-gap: 10px;
    margin-top: 15px;
    text-align: center;
    justify-content: end;
}

.main-search-input-item.search-input input.react-autosuggest__input {
    text-transform: capitalize;
}

.main-search-input-item.search-input input.form-control.c1.near-feild {
    text-transform: capitalize;
}

main.listing-list h6.pb-1 {
    text-transform: capitalize;
}
.ListingBox h2.heading-listing {
    text-transform: capitalize;
}

.posts h2 {
    display: inline-block;
}
.posts span.see-more {
    display: inline-block;
    color: #5CD3B7;
    padding-left: 5px;
    font-size: 12px;
    font-weight: 600;
}

.posts span.see-more a {
    color: #5CD3B7;
}
.content-area.personal.checkout{
    font-size: 14px;
    font-weight: 400;
    color: #4F4F4F;
}

.content-area.personal.checkout svg {
    font-size: 16px;
    color: #00B1CC;
}
.checkout-page .billing-form-item .btn-box button.theme-btn {
    font-size: 16px;
        font-weight: 700;
        width: 100%;
        background: linear-gradient(
    137.27deg
    , #2F88FF 19.41%, #1D68CD 65.49%);
        box-shadow: 0px 4px 4px rgb(14 53 191 / 25%);
        border-radius: 6px;
}

.checkout-page .btn-box.purchase button.theme-btn{
    font-size: 16px;
    font-weight: 700;
    width: 50%;
    background: linear-gradient(
137.27deg
, #2F88FF 19.41%, #1D68CD 65.49%);
    box-shadow: 0px 4px 4px rgb(14 53 191 / 25%);
    border-radius: 6px;
}

.package_plan.tab-content .y-page-link {
    padding: 20px 15px  0 15px !important;
}
.border-bootom {
    border-bottom: 1px solid #EBECED;
    padding-bottom: 20px;
}
.account-page .current-pkg {
    width: 100%;
}

.account-page .current-pkg .pkg-box {
    width: 100%;
}
.account-page .current-pkg .bg-FF6435 {
    background: #FF6435 !important;
    color: #fff !important;
    font-size: 16px;
    font-weight: 700;
    display: inline-block;
    border-radius: 7px;
    width: 100%;
    text-align: center;
    line-height: 39px;

}

.account-page .current-pkg a {
    width: 100%;
   
}
main.dashboard-page .account-page .y-page-link ul.PackageDetails{
    padding-left: 0 !important;

}
main.dashboard-page .account-page .y-page-link ul.PackageDetails li{
    list-style: none !important;
    display: inline-flex;
    margin-bottom: 5px;

}
main.dashboard-page .account-page .y-page-link ul.PackageDetails li span {
    padding-right: 5px;
}
main.dashboard-page .account-page .y-page-link ul.PackageDetails li svg {
    font-size: 25px;
    color: #F3B007;
}

main.dashboard-page .account-page .y-page-link ul.PackageDetails li span.paid svg{
    color: #1DABF2;
}

main.dashboard-page section.dashboard-area.account-page .y-page-link .border-bootom h2 {
    font-size: 30px;
}
.content-area.personal.checkout li {
    display: inline-flex;
    margin-bottom: 5px;
}

.content-area.pt-4.personal.target.checkout h3 {
    font-weight: 500;
    font-size: 20px;
    color: #000;
}
.content-area.pt-4.personal.target.checkout li:first-child {
    color: #000;
}

.content-area.pt-4.personal.target.checkout li {
    border-bottom: 0;
    font-weight: 500;
    font-size: 16px;
    color: #595959;
}

.content-area.pt-4.personal.target.checkout li:first-child {
    color: #000;
    margin-top: 10px;
}
main.dashboard-page.account-page .nav-item a.nav-link {
    width: 190px;
    display: inline-block;
    border: 1px solid #E1E1E1;
    border-radius: 12px 12px;
    margin: 0 0 0 -20px;
    color: #000;
    padding: 10px 0 10px 0px;
}
main.dashboard-page.account-page .nav-item {
    text-align: center;
    margin: 0 0 40px 0;
}
main.dashboard-page.account-page .nav-item a.nav-link.active{
    position: relative;
    z-index: 1;
    border: 0;
    color: #fff;
}
.checkout-page .payment-content .contact-form-action label.label-text {
    font-size: 14px;
    color: #333f57;
    font-weight: 600;
    text-transform: capitalize;
    position: relative;
    z-index: 1;
    background: #E8F0FB;
    left: 5px;
    padding: 0 10px;
}
.payment-tab .payment-trigger input{
    background: #E8F0FB;
}
.checkout-page .payment-content .contact-form-action .form-group, .checkout-page .billing-content .contact-form-action .input-box .basic-multi-select, .billing-content .contact-form-action .form-group {
    position: relative;
    margin-top: -19px;
}
.checkout-page .billing-content .contact-form-action .input-box label.label-text {
    background: #fff;
    position: relative;
    z-index: 1;
    left: 5px;
    padding: 0 10px;
}
.checkout-page .basic-multi-select .select__control {
    min-height: 50px;
}
.checkout-page .basic-multi-select .select__placeholder {
    font-size: 13px;
    color: #777 !important;
    padding-left: 30px;
}
.payment-tab {
    background: #E8F0FB;
    border: 2px solid #1660CF;
    border-radius: 4px;
}
.checkout-page .payment-option .payment-radio input[type=radio]:checked~.checkmark {
    border-color: #1660CF;
    background: #1660CF;
}
.checkout-page .payment-option .payment-radio .checkmark:after {
    background-color: #fff;
    width: 7px;
    height: 7px;
    left: 4px;
    top: 4px;
}
.color-general {
    background-color: #F11200 !important;
}

.color-promotion {
    background-color: #416B62 !important;
}

.color-useful {
    background-color: #7CCFB7 !important;
}

.color-event {
    background-color: #EC5C2A !important;
}

.checkout-page .billing-title-wrap {
    border: 0;
    padding-bottom: 0px;
}

.checkout-page .widget-title{
    color: #000
}

.checkout-page .p-method{
    color: #4F4F4F;
}
.custom-checkbox label.secure-note {
    display: inline-flex;
    padding: 10px;
    cursor: unset;
}
label.secure-note .p-method {
    color: #828282;
    padding-top: 3px;
}
.input-box a.cvv {
    display: inline-flex;
    padding-top: 8%;
    font-size: 12px;
    color: #2F80ED;
    padding-left: 10px;
}

.guideline-payment h3 {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 15px;
}

.guideline-payment p {
    font-size: 14px;
    font-weight: 400;
}

.checkout-page .booking-list li {
    color: #4F4F4F;
    font-size: 14px;
    font-weight: 400;
}

.checkout-page .booking-list li{
    color: #4F4F4F;
    font-size: 16px;
}

.checkout-page .booking-list.total-list li{
    color: #000;
    font-size: 16px;
}
.checkout-page span.color-text {
    font-size: 16px;
    font-weight: 700;
    color: #000 !important;
}

.post-type {
    display: grid;
    grid-template-columns: 19% 81%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.post-type span {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 3px;
}
.post-type p {
    color: #000;
    font-weight: 500;
}

.post-action.save-button button {
    width: 200px;
    font-size: 18px;
    font-weight: 600;
}
button.save-changes.cancel{
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 5px;
    color: #999999;
}

section.dashboard-area.create-post h4 {
    color: #000;
}

section.dashboard-area.create-post label {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0
}

section.dashboard-area.create-post label p {
    color: #999999;
    font-size: 10px;
    display: inline-block;
    font-weight: 500;
}
section.dashboard-area.create-post .form-group {
    margin-bottom: 20px;
    margin-top: 10px;
}

section.dashboard-area.create-post .y-page-link ul li {
    height: 50px;
    line-height: 50px;
}

main.dashboard-page section.dashboard-area.create-post .y-page-link ul li {
    height: 35px;
    line-height: 35px;
}

section.dashboard-area.create-post .dropzone {
    padding: 0;
    height: 88px;
    line-height: 88px;
}

.card-image.gallery-inline .BrainhubCarousel__container {
    min-height: 184px;
    height: 184px;
    position: absolute;
    top: 0;
}

.card-image.gallery-inline .BrainhubCarousel {
    min-height: 184px;
    height: 184px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.card-image.gallery-inline .BrainhubCarouselItem {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 184px;
    width: 100% !important;
}



.checkout-page .btn-box.purchase button.btn-danger {
    font-size: 16px;
    font-weight: 700;
    width: 48%;
    border-radius: 6px;
    height: 48px;
}

/* main.BusinessHome.BizHome .SubHeading {
    color: #BB291F;
} */
main.BusinessHome.BizHome .accordion__item .btn.btn-link {
    color: #000;
}

main.BusinessHome.BizHome .FaqsQuestion section.faq-area .accordion.accordion-item .card .card-body{
    padding-left: 0;
    color: #000;
}

.BLanding.BNavabar.bLanding ul.side-menu-ul li.b-img-user a img {
    width: 30px;
    height: 30px;
}

.side-menu-wrap.side-bar-dashboard-logo .D-Logo {
    padding: 0 0px 12px 15px;
}

a.disabled{
   pointer-events: none;
   cursor: default;
}

section.dashboard-area .y-page-link h2 sub {
    font-size: 14px;
    font-weight: 400;
}

main.BusinessHome.BizHome a.btn.btn-default:hover {
    background: #B30C00;
}

main.BusinessHome.BizHome .VideoBox a.btn.btn-default:hover {
    background: #5CD3B7;
}

button.update-post-images {
    vertical-align: middle;
    display: flex;
    align-items: center;
    height: 88px;
    padding: 0;
}

main.BusinessHome.BizHome.dashboard-page.Plans.account-page .header-menu-wrapper {
    background: transparent !important;
    padding: 0;
}

main.BusinessHome.BizHome.dashboard-page.Plans.account-page .main-menu-content.BHome.BLanding.d-none.d-sm-block {
    background: transparent;
}

li.info-website {
    width: 50%;
}

li.info-website a.custom-color-326D62 {
    width: 83%;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.fade.WarningAlertModal.modal.show {
    background: rgba(0,0,0,0.5 );
}

.cities.recent.recentReviewSummary .user_review p {
    margin-left: 30px;
}

[contentEditable=true]:empty:not(:focus):before{
    content:attr(data-ph)
}


.cities.recent.posts .card-item.col-last .user_review img.card__img {
    width: auto;
    background: transparent;
}
.cities.recent.posts .btn.btn-link.card__img.img-thumbnail.default:hover, .cities.recent.posts .btn.btn-link.card__img.img-thumbnail.btn.btn-none:hover {
    background: #eee;
}
main.home-1 .cities.recent.posts .btn.btn-link.card__img.img-thumbnail.default, main.home-1 .cities.recent.posts .btn.btn-link.card__img.img-thumbnail.btn.btn-none{margin-bottom: 10px}
.cities.recent.posts .btn.btn-link.card__img.img-thumbnail.default, .cities.recent.posts .btn.btn-link.card__img.img-thumbnail.btn.btn-none{
    width: 50%;
    margin-top: 10px;
  
}
ul.rating-list.distance.filter-category.mt-4.zipcode-post {
    column-count: 6;
}


.comment-emoji-area {
    position: absolute;
    right: 55px;
    top: 4px;
}


button.btn.btn-link.card__img.img-thumbnail.commentLikeButton {
    float: right;
}

button.btn.btn-link.card__img.img-thumbnail.commentLikeButton svg {
   font-size: 24px;
}

.card-header.post-view-header .reply-comment-shown.post-view.align-items-center i.post-view-button {
    width: auto;
    font-size: 12px;
}

.like-commment.comment-open {
    cursor: pointer;
}

.post-meta.post-reaction-count , .user_review.post-reaction-count {
    border-top: 1px solid #eeeeef;
    border-bottom: 1px solid #eeeeef;
    padding-bottom: 10px;
}

.cities.recent .card-item.col-last .user_review.post-reaction-count.not-login {
    border-top: 1px solid #eeeeef;
    padding-bottom: 10px;
}


.stroy-media.post-details-page.review_page .story-player, 
.stroy-media.post-details-page.review_page .LeftSideArea, .stroy-media.post-details-page.review_page .story-slider.view-story  {
    height: 100%;
}



.stroy-media.post-details-page.review_page .comment-time button.btn.btn-link.card__img.img-thumbnail.btn.btn-none {
    width: auto;
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: #8991A0;
    font-weight: 500;
    margin: 0 15px 0 0px;
}

.add-comment.postDetailsPage .user-img-comment {
    width: 36px;
    height: 36px;
}
.add-comment.postDetailsPage textarea.pl-3.pt-1.my-0.storycoment.textarea {
    width: 90%;
}
.add-comment.postDetailsPage .comment-emoji-area {
    position: absolute;
    right: 15px;
    top: 2px;
}

.stroy-media.post-details-page .post-meta.postMetaDetails .user_review button.btn.btn-link:hover {
    background: #eee;
}

.stroy-media.post-details-page .post-meta.postMetaDetails .user_review {
    padding-bottom: 6px;
}
.post-meta.postMetaDetails img.card__img.img-thumbnail {
    background-color: transparent;
}

.stroy-media.post-details-page.review_page .user_review button.btn.btn-link.btn.btn-none{
    margin: 0 !important;
    min-width: 110px;
}
main.signup-page.login-page .btn-box button.theme-btn.btn-login {
    background: #f11200;
    border-color: #f11200;
    border-radius: 50px;
}

section.dashboard-area.youtube-videos .accordion__item {
    border: 1px solid #ccc;
}

main.dashboard-page .dashboard-area .y-page-link .chat-meta-button ul.User-Chat-Button li {
    padding: 0;
    color: #000000;
    margin: 0 5px;
}

section.PartnerLOGO .Items img {
    width: 211px;
    border-radius: 5px;
}